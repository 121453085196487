import { Section, SectionItem, SectionItemContent, Switch } from 'Components'

const notifications = [
  {
    name: 'Invoices',
    description: 'Invoices fhjds hjfds hfjdsk ',
    value: true
  },
  { name: 'Marketing', description: 'Invoices', value: false }
]

export const PushNotifications = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Push Notifications</h3>

      <Section>
        {notifications.map(({ name, description, value }, i) => (
          <SectionItem key={i}>
            <SectionItemContent title={name} description={description} />
            <Switch checked={value} onCheckedChange={() => {}} aria-readonly />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
