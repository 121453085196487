import React from 'react'
import { FiChrome } from 'react-icons/fi'
import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, Skeleton, T, Time } from 'Components'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { getRouteWithParams } from 'Utils'
import { useMySessions } from 'Hooks' // Adjust path as needed

const SessionItemSkeleton = () => {
  return (
    <div className="flex items-center gap-x-4 rounded-xl px-2 py-1">
      <Skeleton className="size-12 rounded-full" />
      <div>
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="mt-1 h-4 w-[200px]" />
      </div>
    </div>
  )
}

export const AppSessions = () => {
  const { allSessions, isLoading } = useMySessions()
  const activeId = useSelector((state) => state.auth.session.jwtId)

  // Find active session and remove from list
  const activeSessionIndex = allSessions.findIndex((session) => session.jwtId === activeId)
  const activeSession = activeSessionIndex !== -1 ? allSessions[activeSessionIndex] : null

  // Create a copy of sessions without the active one
  const sessions = [...allSessions]
  if (activeSessionIndex !== -1) {
    sessions.splice(activeSessionIndex, 1)
  }

  // Handle logout for all other sessions
  const handleLogoutOtherSessions = () => {
    Actions.logout(null, { allOtherSessions: true })
  }

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold sm:tracking-tight">
          <T k="settings.sessions.title" />
        </h1>

        <Section title={T('settings.sessions.current')}>
          {isLoading || !activeSession ? (
            <SessionItemSkeleton />
          ) : (
            <SectionItem to={getRouteWithParams('account.sessions.session', { sessionId: activeId })}>
              <SectionItemHeader icon={FiChrome} hasBackground />
              <SectionItemContent title={activeSession.os} description={'This device'} />
              <SectionItemFooter />
            </SectionItem>
          )}
        </Section>

        {!isLoading && sessions.length > 0 && (
          <Section
            title={T('settings.sessions.active')}
            actionText={T('settings.sessions.logout')}
            action={handleLogoutOtherSessions}
          >
            {sessions.map(({ os, jwtId, refreshedAt }, i) => (
              <SectionItem key={i} to={getRouteWithParams('account.sessions.session', { sessionId: jwtId })}>
                <SectionItemHeader icon={FiChrome} hasBackground />
                <SectionItemContent title={os} description={<Time format="DD/MM/YYYY HH:mm" d={refreshedAt} />} />
                <SectionItemFooter />
              </SectionItem>
            ))}
          </Section>
        )}
      </div>
    </>
  )
}
