import * as React from 'react'

import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  T
} from 'Components'
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom'
import { APP_SETTINGS_ITEMS } from 'Constants'
import { FiChevronLeft } from 'react-icons/fi'
import { useIsMobile } from 'Hooks'
import { cn, getRouteWithParams, routeIs } from 'Utils'

export const Settings = () => {
  const location = useLocation()
  const routes = location.pathname.split('/')
  const backUrl = routes.slice(0, -1).join('/') || '/'
  const previousPage = routes.at(-2)
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const items = APP_SETTINGS_ITEMS.filter((category) => !category.mobileOnly)

  return (
    <Dialog open onOpenChange={() => navigate(`/`)}>
      <DialogContent
        hideCloseIcon={isMobile}
        className="max-w-none overflow-hidden p-0 md:max-h-[500px] md:max-w-[700px] lg:max-w-[800px]"
      >
        <DialogTitle className="sr-only">
          <T key="settings.setting.title" />
        </DialogTitle>
        <DialogDescription className="sr-only">Customize your settings here.</DialogDescription>
        <SidebarProvider>
          <Sidebar collapsible="none" className="h-[500px] max-md:hidden">
            <SidebarContent>
              {items.map(({ items, category }, index) => (
                <SidebarGroup key={index}>
                  {category && <SidebarGroupLabel>{category}</SidebarGroupLabel>}
                  <SidebarGroupContent>
                    <SidebarMenu>
                      {items.map(({ icon: Icon, to, name }, i) => (
                        <SidebarMenuItem key={i}>
                          <SidebarMenuButton asChild isActive={routeIs(to)}>
                            <Link to={getRouteWithParams(to)}>
                              <Icon />
                              <span>
                                <T k={name} />
                              </span>
                            </Link>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      ))}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </SidebarGroup>
              ))}
            </SidebarContent>
          </Sidebar>
          <main className="flex h-dvh flex-1 flex-col overflow-hidden md:h-[500px]">
            <header
              className={cn(
                'flex shrink-0 items-center gap-2 pt-4 transition-[width,height] ease-linear group-has-data-[collapsible=icon]/sidebar-wrapper:h-12',
                routes.length < 4 && 'md:hidden'
              )}
            >
              <Link to={backUrl} className="flex items-center gap-2 px-4">
                <FiChevronLeft />
                {previousPage && (
                  <span>
                    <T k={`settings.${previousPage}.title`} />
                  </span>
                )}
              </Link>
            </header>
            <div className="grow overflow-scroll p-4">
              <Outlet />
            </div>
          </main>
        </SidebarProvider>
      </DialogContent>
    </Dialog>
  )
}
