import { buttonVariants, T } from 'Components'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { getRouteWithParams } from 'Utils'

export const SentInvoices = () => {
  return (
    <>
      <h1 className="pb-6 text-2xl font-semibold sm:tracking-tight">
        <T k={'invoices.title'} />
      </h1>

      <Link to={getRouteWithParams('invoices.create')} className={buttonVariants()}>
        <FiPlus size={5} />
        <span>New</span>
      </Link>
    </>
  )
}
