import { Link } from 'react-router-dom'
import { cn, getRouteWithParams } from 'Utils'
import { buttonVariants } from 'Components'

export const RegisterLink = () => (
  <p className="text-muted-foreground text-sm">
    Nu aveți deja un cont?{' '}
    <Link to={getRouteWithParams('register')} className={cn(buttonVariants({ variant: 'link' }), 'p-0')}>
      Înregistrare
    </Link>
  </p>
)

export const LoginLink = () => (
  <p className="text-muted-foreground text-sm">
    Aveți deja un cont?{' '}
    <Link to={getRouteWithParams('login')} className={cn(buttonVariants({ variant: 'link' }), 'p-0')}>
      Autentificare
    </Link>
  </p>
)

