import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, T } from 'Components'
import { FiBell, FiMail } from 'react-icons/fi'
import { getRouteWithParams } from 'Utils'

const items = [
  { title: 'Push notifications', icon: FiBell, to: getRouteWithParams('account.notifications.push') },
  { title: 'Email notifications', icon: FiMail, to: getRouteWithParams('account.notifications.email') }
]

export const NotificationSettings = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.notifications.title" />
      </h3>

      <Section>
        {items.map(({ icon, to, title }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} />
            <SectionItemContent title={title} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
