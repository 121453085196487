import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Avatar,
  AvatarImage,
  AvatarFallback,
  T
} from 'Components'
import { FiUser, FiLogOut } from 'react-icons/fi'
import { getInitialsByName, getRouteWithParams } from 'Utils'
import { Actions } from 'Store'

export const UserMenu = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.session.user)
  const itemClasses = 'hover:bg-hover rounded-lg flex gap-4 items-center'

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="focus:outline-hidden">
        <Avatar>
          <AvatarImage src={user?.avatarUrl} alt={user?.userName} />
          <AvatarFallback>{getInitialsByName(user?.userName)}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-64 rounded-xl">
        <DropdownMenuItem onClick={() => navigate(getRouteWithParams('account'))} className={itemClasses}>
          <FiUser size={20} />
          <div>
            <h3 className="font-medium">{user?.userName}</h3>
            {user?.email && <p className="text-muted-foreground text-sm">{user?.email}</p>}
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => Actions.logout()} className={`${itemClasses} text-destructive`}>
          <FiLogOut size={20} />
          <T k="home.logout" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
