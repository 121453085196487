import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, T } from 'Components'
import { useApp } from 'Hooks'
import { FiCheck, FiMonitor, FiMoon, FiSun } from 'react-icons/fi'
import { Actions } from 'Store'

const themes = [
  { value: 'system', name: 'settings.theme.system', icon: FiMonitor },
  { value: 'light', name: 'settings.theme.light', icon: FiSun },
  { value: 'dark', name: 'settings.theme.dark', icon: FiMoon }
]

export const AppearanceForm = () => {
  const { theme } = useApp()

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.theme.title" />
      </h3>

      <Section>
        {themes.map(({ name, value, icon }, i) => {
          const isActive = value === theme
          return (
            <SectionItem key={i} onClick={() => Actions.setTheme(value)}>
              <SectionItemHeader icon={icon} hasBackground />
              <SectionItemContent title={T(name)} />
              {isActive && <SectionItemFooter icon={FiCheck} />}
            </SectionItem>
          )
        })}
      </Section>
    </>
  )
}
