import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  ResponsivePopover,
  ResponsivePopoverContent,
  ResponsivePopoverDescription,
  ResponsivePopoverHeader,
  ResponsivePopoverTitle,
  ResponsivePopoverTrigger
} from 'Components'
import { useNavigate } from 'react-router-dom'
import { FiPlusCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { cn, getRouteWithParams } from 'Utils'
import { Actions } from 'Store'
import { useState } from 'react'

export const CompanySelector = ({ children }) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const activeCompanyId = useSelector((state) => state.auth.session?.account?.accountId)
  const companies = useSelector((state) => state.auth.session?.accounts)

  return (
    <ResponsivePopover open={open} onOpenChange={setOpen}>
      <ResponsivePopoverTrigger asChild>{children}</ResponsivePopoverTrigger>
      <ResponsivePopoverContent className="p-0 md:w-72" side="right" align="start">
        <ResponsivePopoverHeader>
          <ResponsivePopoverTitle>Firme</ResponsivePopoverTitle>
          <ResponsivePopoverDescription>Firmele cu care lucrezi</ResponsivePopoverDescription>
        </ResponsivePopoverHeader>

        <Command>
          <CommandInput placeholder="Search company..." />
          <CommandList>
            <CommandEmpty>Nu s-au gasit rezultate.</CommandEmpty>
            <CommandGroup>
              {companies?.map(({ companyName, accountId }, index) => (
                <CommandItem
                  key={index}
                  onSelect={() => {
                    Actions.selectUserAccount(accountId)
                  }}
                  className={cn(
                    'text-sm',
                    activeCompanyId === accountId && 'bg-primary/5 text-primary aria-selected:bg-primary/10'
                  )}
                >
                  <p className="truncate">{companyName}</p>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <CommandSeparator alwaysRender />
          <CommandList>
            <CommandGroup forceMount>
              <CommandItem
                onSelect={() => {
                  setOpen(false)
                  navigate(getRouteWithParams('companies.new'))
                }}
              >
                <FiPlusCircle className="size-5" />
                Adauga alta firma
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </ResponsivePopoverContent>
    </ResponsivePopover>
  )
}
