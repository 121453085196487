import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import PusherClient from 'pusher-js/with-encryption'
import { getProduct } from 'Utils'

export const PusherProvider = ({ children }) => {
  // will subscribe to the pusher
  // the account notifications
  const accountId = useSelector((state) => state.auth.session.accountId)
  // the user notifications
  const userId = useSelector((state) => state.auth.session.userId)
  // the user account notifications
  const userAccountId = useSelector((state) => state.auth.session.userAccountId)

  // take the access token from the store
  const accessToken = useSelector((state) => state.auth.accessToken)

  useEffect(() => {
    const channels = [accountId, userId, userAccountId].filter(Boolean)

    // Unsubscribe from all previous channels
    if (window.pusherClient) window.pusherClient.disconnect()

    window.pusherClient =
      process.env.REACT_APP_PUSHER_ID && accessToken
        ? new PusherClient(process.env.REACT_APP_PUSHER_ID, {
            cluster: 'eu',
            authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
            auth: {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'x-product': getProduct()
              }
            }
          })
        : null

    // Subscribe only after connection is established
    window.pusherClient?.connection.bind('connected', () => {
      channels.filter(Boolean).forEach((id) => {
        const channelName = `private-encrypted-${id}`
        console.info('Subscribing to', channelName)
        const channel = window.pusherClient.subscribe(channelName)
        // route all notifications to the action to be processed
        channel.bind('actions', ({ name, data } = {}) => {
          console.info('onPusherMessageArrived---------------------------->', name, data)
          const action = Actions[`${name}Notification`]
          if (!action) return
          action(data)
        })
      })
    })
  }, [accessToken])

  return children
}
