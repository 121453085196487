import {
  AdminHome,
  AdminSettings,
  AdminUsersList,
  TailwindIndicator,
  AdminContactForm,
  AppLayout,
  NotAuthLayout,
  AdminSettingsLayout,
  UserProfile,
  LoginPanel,
  Logout,
  MainModal,
  ApproveAppPanel,
  AdminAppsList,
  AdminStatus,
  AppSessions,
  AdminSessions,
  AdminHumanUsersList,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs,
  AdminAccountSettings
} from 'Components'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

export const AdminRouter = () => {
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <AppLayout noAccountSelector />,
        children: [
          { index: true, element: <AdminHome /> },
          { path: 'admin-users', element: <AdminUsersList /> },
          { path: 'status', element: <AdminStatus /> },
          { path: 'sessions', element: <AdminSessions /> },
          { path: 'users', element: <AdminHumanUsersList /> },
          { path: 'apps', element: <AdminAppsList /> },
          {
            path: 'settings',
            element: <AdminSettingsLayout />,
            children: [
              { index: true, element: <AdminSettings /> },
              { path: 'adminlogs', element: <EntityActivityLogs /> }
            ]
          },
          {
            path: 'account',
            element: <AdminAccountSettings />,
            children: [
              { index: true, element: <UserProfile /> },
              { path: 'sessions', element: <AppSessions /> },
              { path: 'logs', element: <UserActivityLogs /> }
            ]
          },
          {
            path: 'modals',
            element: <MainModal />,
            children: [{ path: 'app', element: <ApproveAppPanel /> }]
          }
        ]
      },
      {
        path: '/contact',
        element: <AdminContactForm />
      },
      {
        path: '/whoami',
        element: <WhoAmI />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '/login',
        element: <NotAuthLayout />,
        children: [{ index: true, element: <LoginPanel /> }]
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ],
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }
    }
  )

  return (
    <>
      <RouterProvider router={router} />
      <TailwindIndicator />
    </>
  )
}
