import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  T
} from 'Components'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from "sonner"
import { Actions } from 'Store'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const UserEmail = () => {
  const oldEmail = useSelector((state) => state.auth.session?.user?.email)
  const [newEmail, setNewEmail] = useState('')

  const accountFormSchema = z.object({
    email: z
      .string()
      .email()
      .regex(new RegExp(`^(?!${oldEmail}$).*`), {
        message: T('validation.email.new')
      })
  })

  const defaultValues = {
    email: ''
  }

  const form = useForm({ resolver: zodResolver(accountFormSchema), defaultValues })
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting }
  } = form

  const onSubmit = async ({ email }) => {
    await Actions.sendEmailAuthCode({ email })
    setNewEmail(email)
  }

  if (newEmail) return <EmailConfirmationSent email={newEmail} />
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 max-md:pb-16">
        <div className="space-y-0.5">
          <h3 className="text-2xl font-semibold sm:tracking-tight">
            <T k="settings.email.cta" />
          </h3>
          <p className="text-muted-foreground text-sm">
            <T k="settings.email.description" />
          </p>
        </div>

        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <T k="settings.email.new" />
              </FormLabel>
              <FormControl>
                <Input type="email" {...field} required />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="w-full max-md:fixed max-md:bottom-0 max-md:left-0 max-md:px-4 max-md:pb-6">
          <Button type="submit" disabled={!isDirty} isLoading={isSubmitting} className="max-md:w-full">
            <T k="settings.email.save" />
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const EmailConfirmationSent = ({ email }) => {
  const navigate = useNavigate()

  const validationSchema = z.object({
    pin: z.string().min(4)
  })

  const defaultValues = {
    pin: ''
  }

  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })

  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty, isSubmitting }
  } = form

  const onSubmit = async ({ pin }) => {
    const isAuthenticated = await Actions.authenticateWithEmailCode({ email, pin })
    if (isAuthenticated.error) {
      setError('pin', { type: isAuthenticated.error.code, message: isAuthenticated.error.message })
    } else {
      toast.success('Successfully Updated')
      navigate('/settings/account/profile')
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex size-full flex-col gap-6 max-sm:justify-between">
        <div className="space-y-6">
          <div className="space-y-1">
            <h1 className="text-2xl font-bold">Enter code</h1>

            <p className="text-muted-foreground text-sm">
              Am trimis un cod de acces la <span className="text-foreground">{email}</span>
            </p>
          </div>

          <FormField
            control={control}
            name="pin"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <InputOTP maxLength={4} {...field}>
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Didn't receive the code?{' '}
                  <Button type="button" variant="link" className="p-0 text-[0.8rem]">
                    Resend
                  </Button>
                </FormDescription>
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" disabled={!isDirty} isLoading={isSubmitting}>
          Autentificare
        </Button>
      </form>
    </Form>
  )
}
