// calls the API with the given query and variables

import { Actions } from 'Store'
import { getProduct, wait } from 'Utils'

// call the API with the given query and variables
export const callAPI = async ({ query, variables, isRetry, captchaToken, refreshToken, isPublic, allowPublic }) => {
  // when public calls we allways send session data
  if (isPublic) allowPublic = true
  const headers = { 'Content-Type': 'application/json', 'x-product': getProduct() }

  // allow alose some non public calls to send session data
  if (allowPublic) headers['x-sd-allow'] = 'true'
  const body = JSON.stringify({ query, variables })

  // if it's not a public call, get the access token from the store
  const accessToken = !isPublic ? await Actions.getAccessToken() : null

  // not public and no access token, makes no sense to call the API
  if (!allowPublic && !accessToken) return

  // on refresh token we send it in the Authorization header
  if (refreshToken) headers.Authorization = `Refresh ${refreshToken}`
  // on access token we send it in the Authorization header
  else if (accessToken) headers.Authorization = `Bearer ${accessToken}`

  // if we have a captcha token, we send it in the headers
  // we can have captcha with non public calls
  if (captchaToken) headers['x-captcha-token'] = captchaToken

  // call the API
  return fetch(process.env.REACT_APP_API_URL, { method: 'POST', body, headers })
    .then((response) => response.json())
    .then((result) => {
      // console.log('API result', query, variables, result)
      // transform the result to have the errors in the same place
      const errors = result?.data?.errors || result?.errors || []
      if (errors[0]) {
        const code = errors[0].extensions?.code || ''
        const details = errors[0].extensions?.details || ''
        const message = errors[0].message || ''
        // this special code will instruct the app to logout the user immediately
        if (code === 'MUST_LOGOUT') Actions.logout(true)
        else return { error: { code, details, message } }
      }
      // console.info('API result', result?.data)
      return result?.data || {}
    })
    .catch((error) => {
      console.error('API error', error)
      // we route all other errors to the retry logic
      return { error: { code: 'MUST_RETRY', message: 'Server down, please retry later' } }
    })
    .then((result) => {
      // if we have a retry error, we retry the call
      if (result?.error?.code === 'MUST_RETRY') {
        console.error('API retry', query, variables)
        // if we have already retried, we return the error
        if (isRetry) return { error: { code: 'SERVER_ERROR', message: 'Server error, please retry later' } }
        // otherwise we wait and retry
        return wait(1).then(() => callAPI({ query, variables, isRetry: true, captchaToken }))
      }
      return result
    })
}
export default callAPI
