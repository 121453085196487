import { parseJSON, randomString } from 'Utils'

// Cache utility for API actions
export const cachedAction = (actionFn, cacheDurationMinutes = 10) => {
  const cache = {}

  if (cacheDurationMinutes < 1) cacheDurationMinutes = 1
  return async (...args) => {
    // we only cache if we have a jwtId and jwtId is part of the cache key
    const jwtId = parseJSON(localStorage.getItem('auth'))?.session?.jwtId || randomString(10)
    const cacheKey = JSON.stringify(args) + jwtId
    const now = new Date().getTime()

    // Check if we have a valid cached result
    if (cache[cacheKey]) {
      // If we have a promise in progress or valid cached result, return it
      if (cache[cacheKey].promise || now - cache[cacheKey].timestamp < cacheDurationMinutes * 60 * 1000) {
        // console.log(`[CACHE HIT] Using cached result for: ${cacheKey}`)
        return cache[cacheKey].promise
      }
    }
    cache[cacheKey] = {}
    // console.log(`[CACHE MISS] Fetching fresh data for: ${cacheKey}`)

    cache[cacheKey].promise = actionFn(...args).then((result) => {
      // Update the cache with the resolved data
      cache[cacheKey].timestamp = now

      // console.log(`[CACHE UPDATE] Stored new result for: ${cacheKey}`)
      return result
    })

    return cache[cacheKey].promise
  }
}
