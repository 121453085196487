import { FiSearch } from 'react-icons/fi'
import {
  Input,
  NewClient,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader,
  T
} from 'Components'
import { useState } from 'react'
import { getRouteWithParams } from 'Utils'

const people = [
  { name: 'Leslie Alexander', lastActivity: 'Paid your invoice yesterday' },
  { name: 'Michael Foster' },
  { name: 'Dries Vincent', lastActivity: 'You sent an invoice 3h ago' },
  { name: 'Lindsay Walton', lastActivity: 'Paid your invoice 3h ago' },
  { name: 'Courtney Henry', lastActivity: 'You sent an invoice 3h ago' },
  { name: 'Tom Cook', lastActivity: 'Paid your invoice 3 days ago' }
]

function groupAndSortByName(people) {
  const groups = {}

  for (const person of people) {
    const firstLetter = person.name[0].toUpperCase()

    if (!groups[firstLetter]) {
      groups[firstLetter] = []
    }
    groups[firstLetter].push(person)
  }

  return Object.entries(groups).sort()
}

export const Clients = () => {
  const [searchItem, setSearchItem] = useState('')

  const handleInputChange = (e) => {
    setSearchItem(e.target.value)
  }

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <h1 className="text-2xl font-semibold sm:tracking-tight">
          <T k={'clients.title'} />
        </h1>

        <NewClient />
      </div>

      <div className="bg-background sticky top-0 z-40 space-y-3 pt-1 pb-2">
        <Input type="search" icon={FiSearch} value={searchItem} onChange={handleInputChange} placeholder="Name, CIF" />
        <div className="flex items-center"></div>
      </div>

      {searchItem ? <SearchedClients people={people} searchItem={searchItem} /> : <Contacts people={people} />}
    </>
  )
}

const SearchedClients = ({ people, searchItem }) => {
  const items = people.filter(({ name }) => name.toLowerCase().includes(searchItem.toLowerCase()))
  return (
    <Section title={`${items.length} items found`}>
      {items.map(({ imageUrl, name, lastActivity }, i) => (
        <SectionItem key={i} to={getRouteWithParams('clients.client', { clientId: 32 })}>
          <SectionItemHeader avatarUrl={imageUrl} alt={name} />
          <SectionItemContent title={name} description={lastActivity} />
          <SectionItemFooter />
        </SectionItem>
      ))}
    </Section>
  )
}

const Contacts = ({ people }) => {
  const letters = groupAndSortByName(people)
  return letters.map(([letter, items]) => (
    <Section title={letter}>
      {items.map(({ imageUrl, name, lastActivity }, i) => (
        <SectionItem key={i} to={`/clients/372`}>
          <SectionItemHeader avatarUrl={imageUrl} alt={name} />
          <SectionItemContent title={name} description={lastActivity} />
          <SectionItemFooter />
        </SectionItem>
      ))}
    </Section>
  ))
}
