export const baseInitialState = {
  isLoadingApp: true,
  theme: 'light'
}

export const baseReducers = {
  setIsLoadingApp: (state, action) => {
    state.isLoadingApp = !!action.payload
  },
  setTheme: (state, action) => {
    if (!['light', 'dark', 'system'].includes(action.payload)) {
      state.theme = 'system'
    } else {
      state.theme = action.payload
    }
  }
}
