import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { getObjectFromBase64, getQueryParamsKey, setQueryParam } from 'Utils'

// use it on the main layout outside the authentication routes
export const useNotAuthenticatedRedirects = () => {
  const navigate = useNavigate()
  // userId and accountId are both set if fully auth layout is loaded
  const isFullAuth = useSelector((state) => !!state.auth.session.userId && !!state.auth.session.accountId)
  const isAlreadyLoginRoute = useLocation().pathname.startsWith('/login')

  // we have an invitation, an app or an app user
  const isRegisterRoute = useSelector(
    (state) => state.auth.session.invitation || state.auth.session.account || state.auth.session.accountId
  )

  useEffect(() => {
    if (isFullAuth) navigate('/')
    if (isAlreadyLoginRoute) return
    else if (!isRegisterRoute) navigate('/register')
    else navigate('/login')
  }, [navigate, isFullAuth, isAlreadyLoginRoute, isRegisterRoute])
}

// use it on the main layout inside the authentication routes
export const useAuthenticatedRedirects = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // userId and accountId are both set if fully auth layout is loaded
  const isFullAuth = useSelector((state) => !!state.auth.session.userId && !!state.auth.session.accountId)

  const isAlreadyInviteRoute = location.pathname === '/modals/invite'
  const hasInvitation = useSelector((state) => !!state.auth.session.invitation)

  useEffect(() => {
    if (isAlreadyInviteRoute) return
    if (hasInvitation) navigate('/modals/invite')
  }, [navigate, hasInvitation, isAlreadyInviteRoute])

  useEffect(() => {
    if (!isFullAuth) navigate('/login')
  }, [navigate, isFullAuth])
}

// use it on the main layout outside the authentication routes
export const useAuthInit = () => {
  useEffect(() => {
    const casesForInitialAuth = async () => {
      // take eventual query passed to any page token and error
      // are usually used by sending links to email or other external app channels
      const token = getQueryParamsKey('token')
      const error = getQueryParamsKey('error')

      // if we have a token, we authenticate with it
      if (token) {
        setQueryParam('token')
        await Actions.authenticateWithToken(token)
      } else if (error) {
        // delete the query error param to avoid it to be passed again
        setQueryParam('error')
        // set the error in the state as it is base64 encoded
        Actions.setErrorAuth(getObjectFromBase64(error))
      } else {
        // get the user data in the start of the app
        await Actions.getMe()
      }
      Actions.setIsLoadingApp(false)
    }
    casesForInitialAuth()
  }, [])
}
