import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot
} from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { FiArrowLeft } from 'react-icons/fi'

const validationSchema = z.object({
  pin: z.string().min(4)
})

// the executeRecaptcha is passed from the parent component
export const EmailSentForm = ({ executeRecaptcha }) => {
  const requestedEmail = useSelector((state) => state.auth.session.requestedEmail)
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)

  const form = useForm({ resolver: zodResolver(validationSchema) })

  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty }
  } = form

  const onSubmit = useCallback(
    async ({ pin }) => {
      const captchaToken = await executeRecaptcha?.('login')
      const isAuthenticated = await Actions.authenticateWithEmailCode({ pin, email: requestedEmail, captchaToken })
      if (isAuthenticated?.error) {
        console.log(isAuthenticated)
        setError('pin', { type: isAuthenticated.error.code, message: isAuthenticated.error.message })
      }
    },
    [requestedEmail, executeRecaptcha]
  )

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex size-full max-w-lg flex-col gap-6 max-sm:justify-between sm:justify-center"
      >
        <div className="space-y-6">
          <Button type="button" onClick={() => Actions.logout()} variant="outline" size="icon" className="rounded-full">
            <FiArrowLeft />
          </Button>

          <div className="space-y-1">
            <h1 className="text-2xl font-bold">Enter code</h1>

            <p className="text-muted-foreground text-sm">
              Am trimis un cod de acces la <span className="text-foreground">{requestedEmail}</span>
            </p>
          </div>

          <FormField
            control={control}
            name="pin"
            render={({ field }) => (
              <FormItem className="w-full text-center">
                <FormControl>
                  <InputOTP maxLength={4} {...field}>
                    <InputOTPGroup className="w-full justify-center">
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Didn't receive the code?{' '}
                  <Button variant="link" className="p-0 text-[0.8rem]">
                    Resend
                  </Button>
                </FormDescription>
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" disabled={!isDirty} isLoading={isLoadingAuth}>
          Autentificare
        </Button>
      </form>
    </Form>
  )
}
