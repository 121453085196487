import { useLocalStorageSlice } from 'Hooks'
import { useEffect } from 'react'
import { Actions } from 'Store'
import { Navigate } from 'react-router-dom'

export const AuthProvider = ({ children }) => {
  // will preserve the session data in the local storage
  useLocalStorageSlice('auth', ['session', 'accessToken', 'refreshToken', 'accessTokenExpiresAt', 'refreshTokenExpiresAt'])
  return children
}

// will logout and redirect to the login page
export const Logout = () => {
  useEffect(() => {
    Actions.logout()
  }, [])
  return <Navigate to="/login" />

}
