export const AppInvitationCard = ({ invitation }) => {
  if (!invitation) return null
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-900">Ai fost invitat să faci dezvoltare pentru aplicatia:</h3>
          <p className="text-sm text-gray-600">
            <span className="text-[10px] font-bold">#{invitation.app?.accountId}</span> <b>{invitation.app?.appName}</b>
          </p>
        </div>
      </div>

      {invitation && (
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">De cǎtre:</span>
            <span className="text-sm font-bold text-gray-900">{invitation.userName}</span>
            <span className="text-sm text-gray-900">{invitation.email}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export const AppCard = ({ app }) => {
  if (!app) return null
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div>
          <h3 className="text-lg font-semibold text-gray-900">Aplicația</h3>
          <p className="text-sm text-gray-600">
            {app.accountId ? `#${app.accountId}` : ''} <b>{app.appName}</b>
          </p>
        </div>
      </div>
    </div>
  )
}
