import { Actions, State } from 'Store'
import { getProduct } from 'Utils'
import {
  callAPI,
  meQuery,
  authenticateWithEmailCodeQuery,
  authenticateWithTokenQuery,
  logoutQuery,
  saveUserProfileQuery,
  sendEmailAuthCodeQuery,
  acceptInvitationQuery,
  denyInvitationQuery,
  refreshTokensQuery,
  getUserActivityLogsQuery,
  getEntityActivityLogsQuery,
  readNotificationsQuery,
  deleteNotificationsQuery,
  changeUserEmailQuery,
  getNotificationsQuery,
  deleteUserQuery,
  unlinkUserProviderQuery,
  inviteUserAccountQuery
} from 'API'

// get the access token from the store
export const getAccessToken = async () => {
  const accessToken = State().auth.accessToken
  const accessTokenExpiresAt = State().auth.accessTokenExpiresAt
  const refreshToken = State().auth.refreshToken
  const refreshTokenExpiresAt = State().auth.refreshTokenExpiresAt

  if (!accessToken) return
  // if the accessToken is still valid, use it
  if (accessTokenExpiresAt && new Date() < new Date(accessTokenExpiresAt)) {
    console.log('accessToken is still valid')
    return accessToken
  }
  console.log('accessToken is not valid')
  // if accessToken and refresh token is still valid, use the refresh token to get a new access token
  if (refreshToken && refreshTokenExpiresAt && new Date() < new Date(refreshTokenExpiresAt)) {
    const authTokensResult = await callAPI({
      query: refreshTokensQuery(),
      isPublic: true,
      refreshToken
    })
    const { accessToken: newAccessToken, accessTokenExpiresAt: newAccessTokenExpiresAt } = authTokensResult?.refreshTokens || {}
    // if the response has an access token, authenticate new tokens
    if (newAccessToken && newAccessTokenExpiresAt) {
      Actions.refreshAuth({
        accessToken: newAccessToken,
        accessTokenExpiresAt: newAccessTokenExpiresAt
      })
      return newAccessToken
    }
  }
  // any other case, log out
  Actions.logout(true)
}

// will refresh the me session, or log out if session expired
export const getMe = async () => {
  // only if logged in i need to refresh the me session
  const hasAccessToken = !!State().auth.accessToken

  if (hasAccessToken) {
    Actions.setIsLoadingAuth(true)

    const { getMe: result } = (await callAPI({ query: meQuery() })) || {}
    const newSession = result?.jwtId ? result : null
    if (newSession) Actions.setSession(newSession)
  }
  Actions.setIsLoadingAuth(false)
}

// will reset the auth state, calling the API only if not already invoked from calling the API methods
export const logout = async (skipAPICall, { allOtherSessions, jwtId } = {}) => {
  if (allOtherSessions || jwtId) {
    const isAuth = !!State().auth.accessToken
    if (!isAuth) return
    await callAPI({ query: logoutQuery(), variables: { allOtherSessions: !!allOtherSessions, jwtId } })
    return
  }
  Actions.setIsLoadingAuth(true)
  // will calle the API only if not already invoked from calling the API methods
  if (!skipAPICall) await callAPI({ query: logoutQuery() })
  Actions.setIsLoadingAuth(false)
  // cleanup state
  Actions.setAuth()
  Actions.resetLists()
}

export const onNewTokensArrived = (authTokens) => {
  const isFullAuth = State().auth.session.accountId && State().auth.session.userId
  // in case of error, it will be set in the state, otherwise the auth tokens will be set
  // if null will stay logged out
  if (isFullAuth) {
    // if nothing or error, display the error toast, but do not logout the user
    if (authTokens?.error || !authTokens) {
      Actions.setIsLoadingAuth(false)
    } else Actions.setAuth(authTokens)
  } else {
    // if error, set it in the state, otherwise set the auth tokens or clear the auth state
    if (authTokens?.error) {
      Actions.setErrorAuth(authTokens.error)
    } else Actions.setAuth(authTokens)
  }
}

// will send an _email with an auth code
export const sendEmailAuthCode = async ({ email, captchaToken } = {}) => {
  if (!email) return
  // send the code with loader
  Actions.setIsLoadingAuth(true)

  // will request the send _email auth code and returns true if the code was sent, otherwise the error
  const { error, sendEmailAuthCode: isSent } =
    (await callAPI({
      query: sendEmailAuthCodeQuery(),
      variables: { email },
      allowPublic: true,
      captchaToken
    })) || {}
  Actions.setIsLoadingAuth(false)
  return error ? { error } : isSent
}

// with take the one time token, and return the response tokens or the error
export const authenticateWithToken = async (token) => {
  if (!token) return
  const { error, authenticateWithToken: result } =
    (await callAPI({
      query: authenticateWithTokenQuery(),
      variables: { token },
      allowPublic: true
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

// will make the actual login with the _email and code
export const authenticateWithEmailCode = async ({ email, pin, captchaToken }) => {
  Actions.setIsLoadingAuth(true)
  const { error, authenticateWithEmailCode: result } =
    (await callAPI({
      query: authenticateWithEmailCodeQuery(),
      variables: { email, pin },
      captchaToken,
      allowPublic: true
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
  return error ? { error } : result
}

export const expireRequestedEmail = () => {
  // set a timeout for 5 minutes and then expire the requested email
  setTimeout(
    () => {
      const { requestedEmail, email } = State().auth.session
      // check if the requested email is still there
      if (!email && requestedEmail) Actions.mergeSession({ requestedEmail: null })
    },
    5 * 60 * 1000
  )
}

// ---------------------------------------

export const getNotifications = async () => {
  const { error, getNotifications: result } = (await callAPI({ query: getNotificationsQuery() })) || {}
  const notifications = error ? { error } : result || []
  if (notifications?.error) {
    Actions.setErrorAuth(notifications.error)
  } else Actions.populateList({ list: 'notifications', items: notifications, idKey: 'id' })
}

export const _deleteUser = async () => {
  const { error, deleteUser: result } =
    (await callAPI({
      query: deleteUserQuery(),
      allowPublic: true
    })) || {}
  return error ? { error } : result?.session?.jwtId ? result : null
}

export const getUserActivityLogs = async () => {
  const { error, getUserActivityLogs: result } = (await callAPI({ query: getUserActivityLogsQuery() })) || {}
  return error ? { error } : result || []
}

export const getEntityActivityLogs = async () => {
  const { error, getEntityActivityLogs: result } = (await callAPI({ query: getEntityActivityLogsQuery() })) || {}
  return error ? { error } : result || []
}

export const changeUserEmail = async ({ email }) => {
  if (!email) return
  const { error, changeUserEmail: result } =
    (await callAPI({
      query: changeUserEmailQuery(),
      variables: { email },
      allowPublic: true
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  return !!authTokens
}

// saves the user profile
export const saveUserProfile = async ({ captchaToken, ...userData } = {}) => {
  // Actions.setIsLoadingAuth(true)
  console.log('saveUserProfile', captchaToken, userData)
  const { error, saveUserProfile: result } =
    (await callAPI({
      query: saveUserProfileQuery(),
      variables: { userData },
      allowPublic: true,
      captchaToken
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

export const handleProviderLogin = async (provider) => {
  provider = provider.toLowerCase()
  const accessToken = State().auth.accessToken
  Actions.setIsLoadingAuth(true)
  let headers = { 'x-product': getProduct(), 'x-sd-allow': true }
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`
  // Get OAuth URL from backend
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/${provider}/url`, { headers }).catch(() => {})
  // Check the content type of the response
  const contentType = response?.headers?.get('content-type')
  let error = { code: 'OAUTH_ERROR', message: 'Nu s-a putut obține URL-ul de autentificare' }
  const url = contentType?.includes('application/json') ? (await response?.json())?.url : await response?.text()
  if (url && url.includes('http')) window.location.href = url
  else Actions.setErrorAuth(error)
}

export const unlinkProviderLogin = async (provider) => {
  if (!provider) return
  provider = provider.toLowerCase()
  if (!provider) return null
  const { error, unlinkUserProvider: result } =
    (await callAPI({
      query: unlinkUserProviderQuery(),
      variables: { provider }
    })) || {}
  const authTokens = error ? { error } : result || null
  Actions.onNewTokensArrived(authTokens)
  return true
}

export const _acceptInvitation = async () => {}

export const acceptInvitation = async () => {
  Actions.setIsLoadingAuth(true)
  const { error, acceptInvitation: result } =
    (await callAPI({
      query: acceptInvitationQuery()
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

export const denyInvitation = async () => {
  Actions.setIsLoadingAuth(true)
  const { error, denyInvitation: result } =
    (await callAPI({
      query: denyInvitationQuery()
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

export const populateUserSessions = (userSessions) => {
  Actions.populateList({ list: 'userSessions', items: userSessions, idKey: 'jwtId' })
}

export const populateUserActivityLogs = (userActivityLogs) => {
  Actions.populateList({ list: 'userActivityLogs', items: userActivityLogs?.items, idKey: 'id' })
}

export const populateEntityActivityLogs = (entityActivityLogs) => {
  Actions.populateList({ list: 'entityActivityLogs', items: entityActivityLogs?.items, idKey: 'id' })
}

export const readNotifications = async (ids) => {
  const { error, readNotifications: result } = (await callAPI({ query: readNotificationsQuery(), variables: { ids } })) || {}
  return !error && result
}

export const deleteNotifications = async (ids) => {
  const { error, deleteNotifications: result } = (await callAPI({ query: deleteNotificationsQuery(), variables: { ids } })) || {}
  return !error && result
}

export const inviteUserAccount = async ({ roles, userName, email } = {}) => {
  const { inviteUserAccount: result } =
    (await callAPI({
      query: inviteUserAccountQuery(),
      variables: {
        userName,
        email,
        roles
      }
    })) || {}
  return result || false
}
