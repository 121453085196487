import { getProduct } from 'Utils'
import { APP_ROUTES, ADMIN_ROUTES, DEV_ROUTES } from 'Constants'

export const getProductName = () => {
  const product = getProduct().toLowerCase()
  if (product === 'webapp') return 'WebApp'
  if (product === 'devplatform') return 'DevPlatform'
  if (product === 'admintool') return 'AdminTool'
  return ''
}

export const getRoutes = () => {
  const product = getProduct().toLowerCase()
  if (product === 'webapp') return APP_ROUTES
  if (product === 'devplatform') return DEV_ROUTES
  if (product === 'admintool') return ADMIN_ROUTES
  return []
}

export const getRouteWithParams = (name, params = {}) => {
  const routes = getRoutes()
  const path = routes[name]
  if (!path) return name

  // Replace placeholders in the path
  const replacedPath = path.replace(/\{([^}]+)\}/g, (_, key) => params[key] || '')

  // Create query string from remaining params (excluding placeholder keys)
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key]) => !path.includes(`{${key}}`)))
  const queryString = new URLSearchParams(queryParams).toString()
  return queryString ? `${replacedPath}?${queryString}` : replacedPath
}

export const isInternalRoute = (name) => !name.startsWith('http')

export const routeIs = (name) => {
  const pathname = window.location.pathname
  return pathname.startsWith(getRouteWithParams(name))
}
