import { getProduct, wait } from 'Utils'
import { Actions } from 'Store'

class GraphQLError extends Error {
  constructor(code, details, message) {
    super(message)
    this.name = 'GraphQLError'
    this.code = code
    this.details = details
  }
}

// Helper to detect if a query is a mutation
const isMutation = (query) => {
  // Simple check: if the query string starts with "mutation"
  return query.trim().startsWith('mutation')
}

export const graphqlClient = async ({
  query,
  variables,
  isRetry = false,
  captchaToken,
  refreshToken,
  isPublic = false,
  allowPublic = false
}) => {
  // when public calls we always send session data
  if (isPublic) allowPublic = true
  const headers = { 'Content-Type': 'application/json', 'x-product': getProduct() }

  // allow also some non public calls to send session data
  if (allowPublic) headers['x-sd-allow'] = 'true'
  const body = JSON.stringify({ query, variables })

  // if it's not a public call, get the access token from the store
  const accessToken = !isPublic ? await Actions.getAccessToken() : null

  // not public and no access token, makes no sense to call the API
  if (!allowPublic && !accessToken) return

  // on refresh token we send it in the Authorization header
  if (refreshToken) headers.Authorization = `Refresh ${refreshToken}`
  // on access token we send it in the Authorization header
  else if (accessToken) headers.Authorization = `Bearer ${accessToken}`

  // if we have a captcha token, we send it in the headers
  // we can have captcha with non public calls
  if (captchaToken) headers['x-captcha-token'] = captchaToken

  // call the API
  return fetch(process.env.REACT_APP_API_URL, { method: 'POST', body, headers })
    .then((response) => response.json())
    .then((result) => {
      const errors = result?.data?.errors || result?.errors || []
      if (errors[0]) {
        const code = errors[0].extensions?.code || ''
        const details = errors[0].extensions?.details || ''
        const message = errors[0].message || ''
        if (code === 'MUST_LOGOUT') {
          Actions.logout(true)
          return
        } else throw new GraphQLError(code, details, message)
      }
      return result?.data || {}
    })
    .catch((error) => {
      console.error('API error', error)
      if (!(error instanceof GraphQLError)) {
        error = new GraphQLError('MUST_RETRY', null, 'Server down, please retry later')
      }
      throw error
    })
    .then((result) => {
      return result
    })
    .catch((error) => {
      if (error.code === 'MUST_RETRY') {
        console.error('API retry', query, variables)
        // For mutations, keep custom retry logic
        if (isMutation(query)) {
          if (isRetry) throw new GraphQLError('SERVER_ERROR', null, 'Server error, please retry later')
          return wait(100).then(() =>
            graphqlClient({ query, variables, isRetry: true, captchaToken, refreshToken, isPublic, allowPublic })
          )
        }
        // For queries, let React Query handle retries
        else {
          return { error: { code: 'MUST_RETRY', message: 'Server down, please retry later' } }
        }
      }
      throw error
    })
}
