import { useSelector } from 'react-redux'
import {
  Button,
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  T
} from 'Components'
import { FiShare2 } from 'react-icons/fi'
import { Actions } from 'Store'
import { useNavigate } from 'react-router-dom'
import { getRouteWithParams } from 'Utils'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const CompanyEmployees = () => {
  const employees = useSelector((state) => state.auth.session?.account?.users)
  const invitations = useSelector((state) => state.auth.session?.account?.invitations)
  const hasInvitations = !!invitations?.length

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.team.title" />
      </h3>

      <Section>
        <InviteEmployeeModal />
      </Section>

      <Section title="Angajați actuali">
        {employees?.map(({ userId, userName, avatarUrl }, i) => (
          <SectionItem key={i} to={getRouteWithParams('settings.company.team.employee', { userId })}>
            <SectionItemHeader avatarUrl={avatarUrl} alt={userName} />
            <SectionItemContent title={userName} description={'Last active: '} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>

      {hasInvitations && (
        <Section title="Pending Invitations">
          {invitations?.map(({ userId, userName, avatarUrl }, i) => (
            <SectionItem key={i} to={getRouteWithParams('settings.company.team.employee', { userId })}>
              <SectionItemHeader avatarUrl={avatarUrl} alt={userName} />
              <SectionItemContent title={userName} description={'Invited on'} />
              <SectionItemFooter />
            </SectionItem>
          ))}
        </Section>
      )}
    </>
  )
}

const formSchema = z.object({ name: z.string() })

const InviteEmployeeModal = () => {
  const navigate = useNavigate()

  const form = useForm({ resolver: zodResolver(formSchema) })
  const isAdmin = form.watch('admin', false)

  const {
    formState: { isSubmitting }
  } = form

  const onSubmit = async ({name}) => {
    console.log(name)
    // i forgot to make this action to hook it
    const url = await Actions.inviteUserAccount({ roles: ['ADMIN'], userName: name })
    console.log(url)

    navigate('/modals/share', { state: { url } })
  }

  return (
    <Credenza>
      <CredenzaTrigger asChild>
        <SectionItem>
          <SectionItemHeader icon={FiShare2} />
          <SectionItemContent title={'Invită colegi'} />
        </SectionItem>
      </CredenzaTrigger>
      <CredenzaContent>
        <CredenzaHeader>
          <CredenzaTitle>Invită colegi</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="admin"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Full Access</FormLabel>
                    <FormDescription>Receive emails about new products, features, and more.</FormDescription>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />

              {!isAdmin && (
                <FormField
                  control={form.control}
                  name="role"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Role</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a verified email to display" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="m@example.com">m@example.com</SelectItem>
                          <SelectItem value="m@google.com">m@google.com</SelectItem>
                          <SelectItem value="m@support.com">m@support.com</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <Button isLoading={isSubmitting} type="submit" className="w-full">
                Share
              </Button>
            </form>
          </Form>
        </CredenzaBody>
      </CredenzaContent>
    </Credenza>
  )
}
