import { useSelector } from 'react-redux'
import { Button, Time } from 'Components'
import { FiMoreVertical, FiEye } from 'react-icons/fi'
import { Actions } from 'Store'
import { Link } from 'react-router-dom'

export const DevApps = () => {
  const apps = useSelector((state) => state.auth.session?.userApps)
  const appUserId = useSelector((state) => state.auth.session?.app?.appUserId)

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-lg font-semibold">Aplicatiile mele</h3>
        <Link to="/modals/app">
          <Button variant="outline" size="sm" className="hover:bg-primary/10 hover:text-primary h-8 transition-colors">
            Adaugǎ o altǎ aplicație
          </Button>
        </Link>
      </div>

      <div className="space-y-2">
        {apps?.map((app) => (
          <div key={app.accountId} className="flex w-full overflow-hidden rounded-lg border border-gray-100 bg-white">
            <div className="w-1 bg-green-500"></div>
            <div className="w-full px-4 py-2">
              <div className="mb-1 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-600">{app.accountId}</span>
                  <span className={`text-lg font-bold ${appUserId === app.appUserId ? 'text-primary' : ''}`}>{app.appName}</span>
                </div>
                <span className="rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-500">
                  <Time d={app.createdAt} format="DD/MM/YYYY" />
                </span>
              </div>
            </div>
            <div className="flex flex-col justify-between px-3 py-2">
              <Button variant="ghost" size="icon" className="hover:bg-muted/70">
                <FiMoreVertical className="h-4 w-4" />
              </Button>
              <Button
                onClick={() => Actions.selectApp(app.appUserId)}
                variant="ghost"
                size="icon"
                disabled={appUserId === app.appUserId}
                className="hover:bg-primary/10 hover:text-primary"
              >
                <FiEye className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
