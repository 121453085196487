import { userFields } from 'API'
export const companyFields = () => `{
  accountId
  companyName
  cif
  address
  users ${userFields()}
}`

export const otherCompaniesFields = () => `{
  accountId
  companyName
  cif
  address
}`

export const employeeFields = () => `{
  accountId
  companyName
  cif
  address
}`
