import { useEffect } from 'react'
import { Actions } from 'Store'
import { CentralLoader, AdminRouter, Main } from 'Components'
import { useAdminLocalStorage, useAuthInit } from 'Hooks'

export const AdminApp = () => {
  // will load the admin initial data
  useEffect(() => {
    const loadData = async () => {
      await Actions.loadAdminInitialData()
      Actions.loadAdminScripts()
    }
    loadData()
  }, [])

  // will load the admin initial data
  useAdminLocalStorage()

  // will initialize the auth
  useAuthInit()

  return (
    <Main loader={<CentralLoader />}>
      <AdminRouter />
    </Main>
  )
}
