import { romanianCompanyFields } from 'API'

export const getRomanianCompanyByCUIQuery = () => `
  query getRomanianCompanyByCUI($cif: ID!) {
    getRomanianCompanyByCUI(cif: $cif) ${romanianCompanyFields()}
  }
`

export const searchRomanianCompaniesQuery = () => `
  query searchRomanianCompanies($text: String!) {
    searchRomanianCompanies(text: $text) ${romanianCompanyFields()}
  }
`

export const sendRomanianWebsiteContactFormQuery = () => `
  mutation sendRomanianWebsiteContactForm($contactData: RomanianWebsiteContactFormData!) {
    sendRomanianWebsiteContactForm(contactData: $contactData)
  }
`
