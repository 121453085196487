import { T } from 'Components'

export const NirInvoices = () => {
  return (
    <>
      <h1 className="pb-6 text-2xl font-semibold sm:tracking-tight">
        <T k={'nir.title'} />
      </h1>
    </>
  )
}
