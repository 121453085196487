import React, { useEffect } from 'react'
import { ListCard, Avatar, Time, Badge, AvatarImage, AvatarFallback } from 'Components'
// import { Actions } from 'Store'
import { getInitialsByName } from 'Utils'

const UserCardItem = ({ item }) => (
  <div className="border-b bg-white transition-colors last:border-b-0 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <div className="flex flex-col gap-2 px-3 py-2 sm:flex-row sm:items-center">
      <Avatar>
        <AvatarImage src={item.avatarUrl} alt="User avatar" />
        <AvatarFallback>{getInitialsByName(item.userName)}</AvatarFallback>
      </Avatar>

      <div className="min-w-[120px] sm:w-40">
        <h3 className="truncate text-sm font-medium dark:text-gray-200">
          <span className="text-xs text-gray-500 dark:text-gray-400">#{item.userId}</span>
          <span className="font-normal dark:text-gray-300"> {item.userName}</span>
        </h3>
      </div>

      <div className="hidden min-w-[180px] grow sm:block">
        <p className="truncate text-sm text-gray-500 dark:text-gray-400">{item.email}</p>
      </div>

      <div className="hidden min-w-[160px] items-center gap-1 text-xs text-gray-500 sm:flex dark:text-gray-400">
        <Time d={item.createdAt} format="DD/MM/YYYY" />
        <span>•</span>
        <Time d={item.lastLoginAt} format="DD/MM/YYYY" />
      </div>

      <div className="flex min-w-[100px] items-center gap-1">
        {item.providers.includes('linkedin') && (
          <Badge variant="secondary" className="bg-blue-50 px-2 py-0.5 text-xs text-blue-700">
            LinkedIn
          </Badge>
        )}
        {item.providers.includes('google') && (
          <Badge variant="secondary" className="bg-red-50 px-2 py-0.5 text-xs text-red-700">
            Google
          </Badge>
        )}
      </div>

      <div className="space-y-0.5 sm:hidden">
        <p className="truncate text-xs text-gray-500 dark:text-gray-400">{item.email}</p>
        <div className="flex items-center gap-1 text-xs text-gray-500 dark:text-gray-400">
          <Time d={item.createdAt} format="DD/MM/YYYY" />
          <span>•</span>
          <Time d={item.lastLoginAt} format="DD/MM/YYYY" />
        </div>
      </div>
    </div>
  </div>
)

export const AdminHumanUsersList = () => {
  useEffect(() => {
    // const syncList = async () => {
    //   const adminHumanUsers = await Actions.getAdminHumanUsers()
    //   if (adminHumanUsers?.error) return
    //   Actions.populateAdminHumanUsers(adminHumanUsers)
    // }
    // syncList()
  }, [])

  return (
    <div className="space-y-4">
      <h2 className="px-3 text-xl font-bold dark:text-gray-200">Toți utilizatorii</h2>
      <div className="rounded-lg border bg-white shadow-xs dark:border-gray-700 dark:bg-gray-800">
        <div className="hidden items-center gap-2 border-b bg-gray-50 px-3 py-2 text-xs font-medium text-gray-500 sm:flex dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400">
          <div className="w-6"></div>
          <div className="w-20">ID</div>
          <div className="w-40 min-w-[120px]">Name</div>
          <div className="min-w-[180px] grow">Email</div>
          <div className="min-w-[160px]">Dates</div>
          <div className="min-w-[100px]">Social</div>
        </div>

        <ListCard id="userId" list="adminHumanUsers" ItemComponent={UserCardItem} isDesc sortKey="lastLoginAt" />
      </div>
    </div>
  )
}
