import {
  Input,
  NewProductModal,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader, T
} from 'Components'
import { useState } from 'react'
import { FiSearch, FiServer } from 'react-icons/fi'
import { getRouteWithParams } from 'Utils'

const products = [{ name: 'dsadsad', description: 'description', icon: FiServer }]

export const Products = () => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <h1 className="text-2xl font-semibold sm:tracking-tight">
          <T k={'products.title'} />
        </h1>

        <NewProductModal />
      </div>

      <div className="bg-background sticky top-0 z-40 space-y-3 pt-1 pb-2">
        <Input type="search" icon={FiSearch} value={searchTerm} onChange={handleInputChange} placeholder="Name" />
        <div className="flex items-center"></div>
      </div>

      <Section title={`${products.length} items found`}>
        {products.map(({ icon, name, description }, i) => (
          <SectionItem key={i} to={getRouteWithParams('products', {})}>
            <SectionItemHeader icon={icon} hasBackground />
            <SectionItemContent title={name} description={description} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
