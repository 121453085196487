import { getAuthTokensFields, commonSessionFields, sessionFields, activityLogFields, notificationFields } from 'API'

export const meQuery = () => `
  query getMe {
    getMe ${commonSessionFields()}
  }`

export const getMySessionsQuery = () => `
  query getMySessions {
    getMySessions ${sessionFields()}
  }`

export const sendEmailAuthCodeQuery = () => `
  mutation sendEmailAuthCode($email: String!) {
    sendEmailAuthCode(email: $email)
  }`

export const changeUserEmailQuery = () => `
  mutation changeUserEmail($email: String!) {
    changeUserEmail(email: $email) ${getAuthTokensFields()}
  }`

export const authenticateWithEmailCodeQuery = () => `
  mutation authenticateWithEmailCode($email: String!, $pin: String!) {
    authenticateWithEmailCode(email: $email, pin: $pin) ${getAuthTokensFields()}
  }`

export const saveUserProfileQuery = () => `
  mutation saveUserProfile($userData: HumanUserPayload!) {
    saveUserProfile(userData: $userData) ${getAuthTokensFields()}
  }`

export const deleteUserQuery = () => `
  mutation deleteUser {
    deleteUser 
  }`

export const unlinkUserProviderQuery = () => `
  mutation unlinkUserProvider($provider: String!) {
    unlinkUserProvider(provider: $provider) ${getAuthTokensFields()}
  }
  `

export const logoutQuery = () => `
  mutation logout($allOtherSessions: Boolean $jwtId: ID) {
    logout(allOtherSessions: $allOtherSessions, jwtId: $jwtId)
  }`

export const refreshTokensQuery = () => `
  mutation refreshTokens {
    refreshTokens {
      accessToken
      accessTokenExpiresAt
    }
  }`

export const authenticateWithTokenQuery = () => `
  mutation authenticateWithToken($token: ID!) {
    authenticateWithToken(token: $token) ${getAuthTokensFields()}
  }`

//-------------------------------
export const acceptInvitationQuery = () => `
  mutation acceptInvitation {
    acceptInvitation ${getAuthTokensFields()}
  }`

export const denyInvitationQuery = () => `
  mutation denyInvitation {
    denyInvitation ${getAuthTokensFields()}
  }`

export const getUserActivityLogsQuery = () => `
  query getUserActivityLogs {
    getUserActivityLogs ${activityLogFields()}
  }`

export const getEntityActivityLogsQuery = () => `
  query getEntityActivityLogs {
    getEntityActivityLogs ${activityLogFields()}
  }`

export const readNotificationsQuery = () => `
  mutation readNotifications($ids: [ID]!) {
    readNotifications(ids: $ids)
  }`

export const deleteNotificationsQuery = () => `
  mutation deleteNotifications($ids: [ID]!) {
    deleteNotifications(ids: $ids)
  }`

export const getNotificationsQuery = () => `
  query getNotifications {
    getNotifications ${notificationFields()}
  }`

export const inviteUserAccountQuery = () => `
  mutation inviteUserAccount($userName: String!, $email: String, $roles: [Role]) {
    inviteUserAccount(userName: $userName, email: $email, roles: $roles)
  }
  `
