import { Button, DateTime, Section, SectionDetails, Skeleton, T } from 'Components'
import { FiLogOut } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Actions } from 'Store'
import { useMySessions } from 'Hooks'

export const SessionDevice = () => {
  let { sessionId } = useParams()
  const navigate = useNavigate()
  const activeId = useSelector((state) => state.auth.session.jwtId)
  const isActive = activeId === sessionId
  const { allSessions, isLoading } = useMySessions()
  const currentSession = allSessions.find((session) => session.jwtId === sessionId)

  const { deviceType, os, browser, ipAddress, createdAt, refreshedAt } = currentSession || {}

  const logout = async () => {
    if (isActive) return await Actions.logout()
    await Actions.logout(null, { jwtId: sessionId })
    navigate(-1)
  }

  return (
    <div className="space-y-5">
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{isLoading ? <Skeleton className="h-8 w-[200px]" /> : os}</h3>
        <div className="text-sm/6 text-gray-500">
          {isLoading ? <Skeleton className="h-5 w-[150px]" /> : isActive ? 'This Device' : <DateTime d={refreshedAt} />}
        </div>
      </div>

      <Section>
        <SectionDetails
          items={[
            { title: 'Browser', value: isLoading ? <Skeleton className="h-5 w-[150px]" /> : browser },
            { title: 'Device', value: isLoading ? <Skeleton className="h-5 w-[150px]" /> : deviceType },
            {
              title: 'Location',
              value: isLoading ? <Skeleton className="h-5 w-[150px]" /> : <DateTime d={createdAt} />
            },
            { title: 'IP', value: isLoading ? <Skeleton className="h-5 w-[150px]" /> : ipAddress }
          ]}
        />
      </Section>

      <Button
        variant="outline"
        icon={FiLogOut}
        onClick={() => logout()}
        isLoading={isLoading}
        disabled={isLoading || !currentSession}
        className="text-destructive"
      >
        <T k={'Întrerupe sesiunea'} />
      </Button>
    </div>
  )
}
