import { T } from 'Components'

export const CompanySettings = () => {
  return (
    <div className="space-y-6">
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.company.title" />
      </h3>
    </div>
  )
}
