import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton
} from 'Components'
import { useIsMobile } from 'Hooks'
import { FiArrowDownCircle, FiPlusCircle } from 'react-icons/fi'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { cn } from 'Utils'
import { Link } from 'react-router-dom'
import { Actions } from 'Store'

export const AppsSelector = () => {
  const isMobile = useIsMobile()
  const [isComboOpen, setIsComboOpen] = useState(false)

  if (isMobile) return <AppsMobile isComboOpen={isComboOpen} setIsComboOpen={setIsComboOpen} />
  return <AppsDesktop isComboOpen={isComboOpen} setIsComboOpen={setIsComboOpen} />
}

const AppsDesktop = ({ isComboOpen, setIsComboOpen }) => {
  const isAppLoading = useSelector((state) => state.auth.isLoadingAuth)
  const appName = useSelector((state) => state.auth.session?.app?.appName)
  return (
    <Popover open={isComboOpen} onOpenChange={setIsComboOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="w-full justify-between overflow-hidden" disabled={isAppLoading}>
          {isAppLoading ? <Skeleton className="h-5 w-full" /> : <span className="truncate text-sm">{appName}</span>}
          <FiArrowDownCircle className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[200px] p-0" align="start">
        <AppsList setIsComboOpen={setIsComboOpen} />
      </PopoverContent>
    </Popover>
  )
}

const AppsMobile = ({ isComboOpen, setIsComboOpen }) => {
  const isAppLoading = useSelector((state) => state.auth.isLoadingAuth)
  const appName = useSelector((state) => state.auth.session?.app?.appName)
  return (
    <Drawer open={isComboOpen} onOpenChange={setIsComboOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" className="w-full justify-between overflow-hidden">
          {isAppLoading ? <Skeleton className="h-5 w-full" /> : <span className="truncate text-sm">{appName}</span>}
          <FiArrowDownCircle className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Aplicatii</DrawerTitle>
          <DrawerDescription>Aplicatiile cu care lucrezi</DrawerDescription>
        </DrawerHeader>
        <div className="mt-4 border-t">
          <AppsList setIsComboOpen={setIsComboOpen} />
        </div>
      </DrawerContent>
    </Drawer>
  )
}

const AppsList = ({ setIsComboOpen }) => {
  const apps = useSelector((state) => state.auth.session?.userApps)
  const sessions = (apps || []).map((session) => ({
    appUserId: session.appUserId,
    accountId: session.accountId,
    appName: session.appName,
    isSelected: true
  }))
  const appUserId = useSelector((state) => state.auth.session?.app?.appUserId)
  const onSelectAccount = async (appUserId) => {
    await Actions.selectApp(appUserId)
    Actions.toggleSidebar(false)
  }
  return (
    <div>
      <Command>
        <CommandInput placeholder="Filter status..." className="ring-0 ring-offset-0" />
        <CommandList>
          <CommandEmpty>Nu s-au gasit rezultate.</CommandEmpty>
          {sessions.length > 0 && (
            <CommandGroup heading="Aplicatii">
              {sessions.map((session) => (
                <CommandItem
                  className={cn(appUserId === session.appUserId ? 'bg-primary/5 text-primary aria-selected:bg-primary/10' : '')}
                  key={session.appUserId}
                  value={session.appName}
                  onSelect={() => {
                    onSelectAccount(session.appUserId)
                    setIsComboOpen(false)
                  }}
                >
                  {session.appName}
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </CommandList>
      </Command>
      <div className="w-full border-b"></div>
      <Link
        to="/modals/app"
        className="text-primary hover:bg-hover m-1 flex cursor-pointer flex-row items-center gap-2 rounded px-2 py-1.5 text-sm font-bold"
        onClick={() => {
          setIsComboOpen(false)
        }}
      >
        <FiPlusCircle className="size-4" />
        <p className="truncate">Adaugǎ altǎ aplicație</p>
      </Link>
    </div>
  )
}
