import {
  LocalesProvider,
  StoreProvider,
  DebugProvider,
  AuthProvider,
  SiteThemeProvider,
  PusherProvider,
  QueryProvider,
  Toaster
} from 'Components'

// the root app exported by the index which will apply the providers
export const RootApp = ({ children }) => {
  return (
    <StoreProvider>
      <QueryProvider>
        <DebugProvider>
          <SiteThemeProvider>
            <LocalesProvider>
              <AuthProvider>
                <PusherProvider>
                  <main>{children}</main>
                  <Toaster />
                </PusherProvider>
              </AuthProvider>
            </LocalesProvider>
          </SiteThemeProvider>
        </DebugProvider>
      </QueryProvider>
    </StoreProvider>
  )
}
