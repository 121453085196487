import { userFields, sessionFields, activityLogFields } from 'API'

export const getAdminHumanUsersQuery = () => `
  query getAdminHumanUsers {
    getAdminHumanUsers ${userFields()}
  }
`

export const getAdminSessionsQuery = () => `
  query getAdminSessions {
    getAdminSessions ${sessionFields()}
  }
`

export const getAdminActivityLogsQuery = () => `
  query getAdminActivityLogs {
    getAdminActivityLogs ${activityLogFields()}
  }
`

export const getAdminAppsQuery = () => `
  query getAdminApps {
    getAdminApps { 
      appUserId
      accountId
      appName
      isApproved
    }
  }
`

export const saveAdminUserQuery = () => `
  mutation saveAdminUser($email: String!, $userName: String, $roles: [String], $isLimited: Boolean) {
    saveAdminUser(email: $email, userName: $userName, roles: $roles, isLimited: $isLimited)}
  }
`

export const deleteAdminUserQuery = () => `
  mutation deleteAdminUser($email: String!) {
    deleteAdminUser(email: $email)
  }
`

export const saveAdminAppQuery = () => `
  mutation saveAdminApp($appData: AppAdminPayload!) {
    saveAdminApp(appData: $appData) {
      appUserId
      isApproved
    }
  }
`
export const approveOrDenyAppQuery = () => `
  mutation approveOrDenyApp($accountId: ID!, $isApproved: Boolean!) {
    approveOrDenyApp(accountId: $accountId, isApproved: $isApproved) 
  }
`
