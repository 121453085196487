import { Actions, State } from 'Store'

export const notificationDeletedNotification = ({ ids }) => {
  Actions.deleteNotifications(ids)
}

export const notificationReadNotification = ({ ids }) => {
  Actions.readNotifications(ids)
}

export const notificationCreatedNotification = (notification) => {
  Actions.insertNotification(notification)
}

export const sessionDeletedNotification = ({ jwtId }) => {
  if (!jwtId) return
  const loggedInJwtId = State().auth.session?.jwtId
  if (loggedInJwtId === jwtId) Actions.logout(true)
  else Actions.deleteListItem({ list: 'userSessions', id: jwtId })
}

export const humanUserUpsertedNotification = (user) => Actions.mergeSession({ user })
