import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Actions } from 'Store'
import {
  AppleAuthButton,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  GoogleAuthButton,
  Input,
  LinkedInAuthButton,
  RegisterLink
} from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { getQueryParamsKey } from 'Utils'
import { FiArrowLeft, FiMail } from 'react-icons/fi'

// the executeRecaptcha is passed from the parent component
export const LoginWithEmailForm = ({ executeRecaptcha, hasLinkedinLogin, hasGoogleLogin, hasAppleLogin, hasRegister }) => {
  const enabledProviders = hasLinkedinLogin || hasGoogleLogin || hasAppleLogin
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const [emailLogin, setEmailLogin] = useState(false)

  if (emailLogin)
    return <LoginEmailForm executeRecaptcha={executeRecaptcha} setEmailLogin={setEmailLogin} hasRegister={hasRegister} />

  return (
    <div className="flex max-w-lg flex-col gap-6 text-center">
      <h1 className="text-2xl font-bold">Sign in to Factcurier</h1>

      <Button variant="outline" onClick={() => setEmailLogin(true)} icon={FiMail} size="lg" className="h-12 rounded-full">
        Continue with email
      </Button>

      {enabledProviders && (
        <>
          <div className="after:border-border relative text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t">
            <span className="bg-background text-muted-foreground relative z-10 px-2">Sau</span>
          </div>

          <div className="space-y-4">
            {hasLinkedinLogin && <LinkedInAuthButton disabled={isLoadingAuth} />}
            {hasGoogleLogin && <GoogleAuthButton disabled={isLoadingAuth} />}
            {hasAppleLogin && <AppleAuthButton disabled={isLoadingAuth} />}
          </div>
        </>
      )}

      {hasRegister && <RegisterLink />}
    </div>
  )
}

const validationSchema = z.object({
  email: z.string().email('Adresă de e-mail invalidă'),
  userName: z
    .string()
    .min(3, 'Numele trebuie să conține minim 3 caractere')
    .max(45, 'Numele trebuie să conține maxim 45 caractere'),
  serverError: z.string().optional()
})

export const LoginEmailForm = ({ executeRecaptcha, setEmailLogin, hasRegister }) => {
  const errorAuth = useSelector((state) => state.auth.errorAuth)
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)

  const defaultValues = useMemo(
    () => ({
      email: getQueryParamsKey('email') || '',
      serverError: errorAuth?.message
    }),
    [errorAuth]
  )
  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })
  const {
    formState: { isDirty }
  } = form

  const onSubmit = useCallback(
    async ({ email }) => {
      const captchaToken = await executeRecaptcha?.('login')

      // await Actions.saveUserProfile({ userName, captchaToken })

      if (email) {
        const isSent = await Actions.sendEmailAuthCode({ email, captchaToken })
        if (!isSent || isSent?.error)
          Actions.setErrorAuth(isSent?.error || { code: 'SERVER_ERROR', message: 'Server error, please retry later' })
        else {
          Actions.mergeSession({ requestedEmail: email })
          Actions.expireRequestedEmail()
          Actions.setIsLoadingAuth(false)
        }
      }
    },
    [executeRecaptcha]
  )
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex size-full max-w-lg flex-col gap-6 max-sm:justify-between sm:justify-center"
      >
        <div className="space-y-6">
          <Button type="button" onClick={() => setEmailLogin(false)} variant="outline" size="icon" className="rounded-full">
            <FiArrowLeft />
          </Button>

          <div>
            <h1 className="text-2xl font-bold">Sign in to Factcurier</h1>
            {hasRegister && <RegisterLink />}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="userName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nume</FormLabel>
                  <FormControl>
                    <Input {...field} required autoFocus />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="userSurname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Surname</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="col-span-full">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} type="email" placeholder="Introduci emailul tău" required />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {errorAuth && <p className="mt-3 text-sm text-red-500">{errorAuth?.message}</p>}

        <Button type="submit" disabled={!isDirty} isLoading={isLoadingAuth} className="w-full justify-self-end">
          Trimite codul
        </Button>
      </form>
    </Form>
  )
}
