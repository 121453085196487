import { Button, CompanySelector, NotificationsMenu, UserMenu } from 'Components'
import { useIsMobile } from 'Hooks'
import { FiArrowLeft, FiChevronDown } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useSelector } from 'react-redux'

export const AppHeader = ({ noAccountSelector = false }) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const activeCompanyName = useSelector((state) => state.auth.session?.account?.companyName)

  const backUrl = ''

  return (
    <header className="mx-auto flex h-16 w-full max-w-(--breakpoint-xl) items-center justify-between gap-8 p-6">
      {backUrl ? (
        <Button onClick={() => navigate(-1)} icon={FiArrowLeft} variant="ghost" className="p-0">
          Inapoi
        </Button>
      ) : (
        isMobile &&
        !noAccountSelector && (
          <CompanySelector>
            <div className="flex items-center">
              <span className="truncate font-semibold">{activeCompanyName}</span>
              <FiChevronDown className="ml-auto" />
            </div>
          </CompanySelector>
        )
      )}
      <div className="flex grow items-center justify-end gap-4">
        <NotificationsMenu />
        {!isMobile && <UserMenu />}
      </div>
    </header>
  )
}
