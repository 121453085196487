import {
  AppHome,
  AppLayout,
  LoginPanel,
  RegisterCompanyPanel,
  NotAuthLayout,
  TailwindIndicator,
  SentInvoices,
  CompanySettings,
  ReceivedInvoices,
  AppSpv,
  NirInvoices,
  UserProfile,
  CompanyEmployees,
  Logout,
  AppSessions,
  AcceptCompanyPanel,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs,
  Notifications,
  Settings,
  MobileSettings,
  SessionDevice,
  Clients,
  Client,
  NotificationSettings,
  Invoice,
  Apps,
  AccountSettings,
  AppCompanies,
  AppearanceForm,
  DeleteAccount,
  CompanyDetails,
  Share,
  EmployeeDetails,
  NewInvoice,
  UserEmail,
  ProfileSettings,
  PreferencesSettings,
  LanguageSelector,
  LoginProviders,
  Billing,
  PushNotifications,
  EmailNotifications,
  Whatsapp,
  TimezoneSelector,
  Products
} from 'Components'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

export const AppRouter = () => {
  const router = createBrowserRouter(
    [
      {
        path: '/whoami',
        element: <WhoAmI />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        element: <NotAuthLayout />,
        children: [
          {
            path: '/login',
            element: <LoginPanel hasGoogleLogin hasAppleLogin hasLinkedinLogin hasRegister />
          },
          {
            path: '/register',
            element: <LoginPanel hasGoogleLogin hasAppleLogin hasLinkedinLogin />
          }
        ]
      },
      {
        path: '/',
        element: <AppLayout />,
        children: [
          { index: true, element: <AppHome /> },
          { path: 'expenses', element: <ReceivedInvoices /> },
          { path: 'products', element: <Products /> },
          { path: 'companies/new', element: <RegisterCompanyPanel /> },
          {
            path: 'invoices',
            children: [
              { index: true, element: <SentInvoices /> },
              { path: 'new', element: <NewInvoice /> },
              { path: ':invoiceId', element: <Invoice /> }
            ]
          },
          { path: 'nir', element: <NirInvoices /> },
          {
            path: 'clients',
            children: [
              { index: true, element: <Clients /> },
              { path: ':clientId', element: <Client /> }
            ]
          },
          { path: 'notifications', element: <Notifications /> },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              { index: true, element: <MobileSettings /> },
              {
                path: 'notifications',
                children: [
                  { index: true, element: <NotificationSettings /> },
                  { path: 'push', element: <PushNotifications /> },
                  { path: 'email', element: <EmailNotifications /> }
                ]
              },
              {
                path: 'preferences',
                children: [
                  { index: true, element: <PreferencesSettings /> },
                  { path: 'theme', element: <AppearanceForm /> },
                  { path: 'language', element: <LanguageSelector /> },
                  { path: 'timezone', element: <TimezoneSelector /> }
                ]
              },
              {
                path: 'account',
                children: [
                  { index: true, element: <AccountSettings /> },
                  {
                    path: 'profile',
                    children: [
                      { index: true, element: <ProfileSettings /> },
                      { path: 'details', element: <UserProfile /> },
                      { path: 'email', element: <UserEmail /> }
                    ]
                  },
                  {
                    path: 'companies',
                    children: [
                      { index: true, element: <AppCompanies /> },
                      { path: ':accountId', element: <CompanyDetails /> }
                    ]
                  },
                  { path: 'delete', element: <DeleteAccount /> },
                  { path: 'logins', element: <LoginProviders /> },
                  {
                    path: 'sessions',
                    children: [
                      { index: true, element: <AppSessions /> },
                      { path: ':sessionId', element: <SessionDevice /> }
                    ]
                  },
                  { path: 'activity', element: <UserActivityLogs /> }
                ]
              },
              { path: 'company', element: <CompanySettings /> },
              { path: 'spv', element: <AppSpv /> },
              {
                path: 'team',
                children: [
                  { index: true, element: <CompanyEmployees /> },
                  { path: ':userId', element: <EmployeeDetails /> }
                ]
              },
              { path: 'billing', element: <Billing /> },
              { path: 'logs', element: <EntityActivityLogs /> },
              { path: 'apps', element: <Apps /> }
            ]
          },
          {
            path: 'apps',
            children: [{ path: 'whatsapp', element: <Whatsapp /> }]
          },
          {
            path: 'modals',
            children: [
              { path: 'share', element: <Share /> },
              { path: 'invite', element: <AcceptCompanyPanel /> }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ],
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }
    }
  )

  return (
    <>
      <RouterProvider router={router} />
      <TailwindIndicator />
    </>
  )
}
