import { Section, SectionItem, SectionItemContent, Switch, T } from 'Components'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'

export const LoginProviders = () => {
  const userProviders = useSelector((state) => state.auth.session?.user?.providers)

  const providers = [
    { name: 'Google', isLinked: userProviders?.includes('google') },
    { name: 'Apple', isLinked: userProviders?.includes('apple') },
    { name: 'Linkedin', isLinked: userProviders?.includes('linkedin') }
  ]

  const handleLoginProvider = async ({ name, isLinked }) => {
    if (!isLinked) return await Actions.handleProviderLogin(name)
    return await Actions.unlinkProviderLogin(name)
  }

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.logins.title" />
      </h3>

      <Section>
        {providers.map((provider, i) => (
          <SectionItem key={i}>
            <SectionItemContent title={provider.name} />
            <Switch checked={provider.isLinked} onClick={() => handleLoginProvider(provider)} />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
