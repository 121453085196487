import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, T } from 'Components'
import { FiClock, FiGlobe, FiSun } from 'react-icons/fi'
import { getRouteWithParams } from 'Utils'

const items = [
  { title: 'settings.language.title', icon: FiGlobe, to: getRouteWithParams('account.preferences.language') },
  { title: 'Theme', icon: FiSun, to: getRouteWithParams('account.preferences.theme') },
  { title: 'Timezone', icon: FiClock, to: getRouteWithParams('account.preferences.timezone') }
]

export const PreferencesSettings = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.preferences.title" />
      </h3>
      <Section>
        {items.map(({ icon, to, title }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} />
            <SectionItemContent title={T(title)} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
