import { Actions } from 'Store'

import {
  callAPI,
  deleteAdminUserQuery,
  getAdminActivityLogsQuery,
  getAdminAppsQuery,
  getAdminSessionsQuery,
  saveAdminAppQuery,
  approveOrDenyAppQuery
} from 'API'

export const loadAdminScripts = () => {}

// will setup the initial store
export const loadAdminInitialData = async () => {}

// @INSTALL put here all the actions for the lists
export const populateAdminUsers = (adminUsers) => {
  Actions.populateList({ list: 'adminUsers', items: adminUsers, idKey: 'email' })
}

export const deleteAdminUser = async (email) => {
  Actions.deleteListItem({ list: 'adminUsers', id: email })
  const { error, deleteAdminUser: result } = (await callAPI({ query: deleteAdminUserQuery(), variables: { email } })) || {}
  return error ? { error } : result || false
}

export const populateAdminApps = (apps) => {
  Actions.populateList({ list: 'adminApps', items: apps, idKey: 'appUserId' })
}

export const upsertAdminApp = (app) => {
  Actions.upsertListItem({ list: 'adminApps', item: app, idKey: 'appUserId' })
}

export const populateAdminHumanUsers = (adminHumanUsers) => {
  Actions.populateList({ list: 'adminHumanUsers', items: adminHumanUsers, idKey: 'userId' })
}

export const populateAdminSessions = (adminSessions) => {
  Actions.populateList({ list: 'adminSessions', items: adminSessions, idKey: 'jwtId' })
}

export const approveOrDenyApp = async ({ accountId, isApproved }) => {
  if (!accountId) return
  const { approveOrDenyApp: result } =
    (await callAPI({ query: approveOrDenyAppQuery(), variables: { accountId, isApproved } })) || {}
  const isAck = result || false
  if (!isAck) return
  await Actions.getNotifications()
}

export const getAdminApps = async () => {
  const { error, getAdminApps: result } = (await callAPI({ query: getAdminAppsQuery() })) || {}
  return error ? { error } : result || []
}

// export const getAdminUsers = async () => {
//   const { error, getAdminUsers: result } = (await callAPI({ query: getAdminUsersQuery() })) || {}
//   return error ? { error } : result || []
// }

// export const getAdminHumanUsers = async () => {
//   const { error, getAdminHumanUsers: result } = (await callAPI({ query: getAdminHumanUsersQuery() })) || {}
//   return error ? { error } : result || []
// }

export const getAdminSessions = async () => {
  const { error, getAdminSessions: result } = (await callAPI({ query: getAdminSessionsQuery() })) || {}
  return error ? { error } : result || []
}

export const getAdminActivityLogs = async () => {
  const { error, getAdminActivityLogs: result } = (await callAPI({ query: getAdminActivityLogsQuery() })) || {}
  return error ? { error } : result || []
}

export const saveAdminApp = async ({ appUserId, isApproved } = {}) => {
  if (!appUserId) return
  const { error, saveAdminApp: result } =
    (await callAPI({ query: saveAdminAppQuery(), variables: { appData: { appUserId, isApproved } } })) || {}
  return error ? { error } : result || false
}
