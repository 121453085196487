import { companyFields, appFields, platformFields, otherCompaniesFields } from 'API'
export const commonUserAccountFields = () => `
  accountId
  roles
  isLimited
`

export const sessionUserFields = () => `{
  email
  userName
  userSurname
  avatarUrl
  providers
  createdAt
  profile {
    age
    hasWhatsapp
  }
}`

export const sessionFields = () => `{
  accountType
  jwtId
  isAuthLink
  isEmailPin
  createdAt
  refreshedAt
  expiresAt
  ipAddress
  location
  browser
  os
  deviceType
}`

export const otherUsersFields = () => `{
  userId
  email
  userName
  userSurname
  avatarUrl
  enrolledAt
  roles
  isLimited
  isBlocked
  lastLoginAt
}`

export const commonSessionFields = () => `{
  jwtId
  accountId
  userId
  userAccountId
  roles
  isLimited
  user ${sessionUserFields()}
  account {
    ... on Company ${companyFields()}
    ... on App ${appFields()}
    ... on Platform ${platformFields()}
  }
  accounts {
    ... on Employee ${otherCompaniesFields()}
    ... on Developer ${userFields()}
    ... on Administrator ${userFields()}
  }
}`

export const getAuthTokensFields = () => `{
  accessToken
  accessTokenExpiresAt
  refreshToken
  refreshTokenExpiresAt
  session ${commonSessionFields()}
}`

export const userFields = () => `{
  userId
  avatarUrl
  email
  userName
  userSurname
  roles
  lastLoginAt
  isLimited
}`

export const invitationFields = () => `{
  jwtId
  email
  userName
  roles
  createdAt
  isLimited
  invitedBy { name email avatarUrl }
}`

export const notificationFields = () => `{
  id
  type
  data
  isRead
  createdAt
  expiresAt
}`

export const activityLogFields = () => `{
  items {
    id
    ts
    type
    data
    caller {
    name
    avatarUrl
    }
  }
  total
  lastTs
  pages
  page
  limit
}`

// export const invitationFields = () => `{
//    jwtId
//    userName
//    email
//    refreshedAt
//    expiresAt
//    roles
//  }`
