import { callAPI, inviteDeveloperQuery, registerAppQuery, selectAppQuery } from 'API'
import { Actions } from 'Store'

// TODO: when closing the invite modal
export const inviteClose = () => Actions.denyInvitation()

export const loadDevScripts = () => {}

// will setup the initial store
export const loadDevInitialData = async () => {}

// saves the user profile
export const registerApp = async (appData) => {
  Actions.setIsLoadingAuth(true)
  const { error, registerApp: result } =
    (await callAPI({
      query: registerAppQuery(),
      variables: { appData },
      allowPublic: true
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

export const selectApp = async (appUserId) => {
  Actions.setIsLoadingAuth(true)
  const { error, selectApp: result } =
    (await callAPI({
      query: selectAppQuery(),
      variables: { appUserId }
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

export const inviteDeveloper = async () => {
  const { inviteDeveloper: result } = (await callAPI({ query: inviteDeveloperQuery() })) || {}
  return result || null
}
