import { Avatar, AvatarFallback, AvatarImage, ResponsiveDropdown, T } from 'Components'
import { cn, getInitialsByName } from 'Utils'
import { Actions } from 'Store'
import { useSelector } from 'react-redux'
import { FiEdit2, FiLoader, FiPlus, FiImage, FiTrash2 } from 'react-icons/fi'
import { useRef, useState } from 'react'

export const ProfileHeader = () => {
  const user = useSelector((state) => state.auth.session.user)
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef(null)

  const setInputRef = (input) => {
    inputRef.current = input
  }

  const removeProfileImage = async () => {
    setIsLoading(true)
    await Actions.saveUserProfile({ avatarUrl: '' })
    setIsLoading(false)
  }

  const handleFileChange = async (e) => {
    if (isLoading) return
    const file = e.target.files?.[0]
    const contentType = file.type
    if (file && contentType.startsWith('image/')) {
      const reader = new FileReader()
      reader.onload = async (event) => {
        setIsLoading(true)
        await Actions.saveUserProfile({ avatarFile: { base64String: event.target?.result, contentType } })
        setIsLoading(false)
      }
      reader.readAsDataURL(file)
    }
  }

  if (isLoading)
    return (
      <Avatar className="mx-auto mt-5 size-16">
        <div className="absolute inset-0 flex items-center justify-center">
          <FiLoader className="animate-spin" />
        </div>
      </Avatar>
    )

  return (
    <div className="mt-5 flex flex-col items-center">
      {user?.avatarUrl && !isLoading ? (
        <ResponsiveDropdown
          title={T('settings.picture.title')}
          description={T('settings.picture.description')}
          trigger={<Trigger setInputRef={setInputRef} user={user} handleFileChange={handleFileChange} />}
          items={[
            { label: T('settings.picture.edit'), icon: FiImage, onClick: () => inputRef.current?.click() },
            { label: T('settings.picture.delete'), icon: FiTrash2, onClick: () => removeProfileImage() }
          ]}
        />
      ) : (
        <Trigger user={user} handleFileChange={handleFileChange} />
      )}
    </div>
  )
}

const Trigger = ({ user, setInputRef, handleFileChange }) => {
  const hasAvatar = !!user?.avatarUrl
  const Icon = hasAvatar ? FiEdit2 : FiPlus
  return (
    <div className="relative cursor-pointer">
      <Avatar className="mx-auto size-16">
        <AvatarImage src={user?.avatarUrl} alt={user?.userName} />
        <AvatarFallback>{getInitialsByName(user?.userName)}</AvatarFallback>
      </Avatar>

      <div className="bg-primary absolute right-0 bottom-0 flex size-5 items-center justify-center rounded-full text-white">
        <Icon size={13} />
      </div>

      <label htmlFor="file-upload" className={cn('absolute inset-0 cursor-pointer', setInputRef && 'hidden')}></label>
      <input id="file-upload" ref={setInputRef} type="file" className="hidden" accept="image/*" onChange={handleFileChange} />
    </div>
  )
}
