import { goodLocale, goodTimezone } from 'Utils'
import { Actions } from 'Store'

// will change the locale
export const setLocale = async (locale) => {
  if (!locale) return

  // set the locale to lowercase
  locale = locale.toLowerCase()

  // if the locale is not supported we ignore the call
  if (!goodLocale(locale)) return

  // affect the state with the new locale and def_initions
  Actions.changeLocale(locale)
}

// will change the timezone
export const setTimezone = (timezone) => {
  if (!goodTimezone(timezone)) return
  Actions.setTimezone(timezone)
}
