import { Children, cloneElement } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react19-google-recaptcha-v3'

export const WithRecaptcha = ({ children }) => {
  if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) return <ChildrenWithRecaptcha isFake>{children}</ChildrenWithRecaptcha>
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: true, // Important for mobile performance
        defer: true, // Important for mobile performance
        appendTo: 'head' // Load in head for better performance
      }}
      container={{
        // Optional but can help with mobile rendering
        parameters: {
          badge: 'inline',
          size: 'invisible'
        },
        language: 'ro'
      }}
    >
      <ChildrenWithRecaptcha>{children}</ChildrenWithRecaptcha>
    </GoogleReCaptchaProvider>
  )
}

const useFakeRecaptcha = () => ({ executeRecaptcha: () => Promise.resolve('fake-token') })
const ChildrenWithRecaptcha = ({ children, isFake = false }) => {
  const { executeRecaptcha: googleExecuteRecaptcha } = useGoogleReCaptcha()
  const { executeRecaptcha: fakeExecuteRecaptcha } = useFakeRecaptcha()
  const executeRecaptcha = isFake ? fakeExecuteRecaptcha : googleExecuteRecaptcha
  const childrenWithProps = isFake
    ? cloneElement(children, { executeRecaptcha })
    : Children.map(children, (child) => {
        return cloneElement(child, { executeRecaptcha })
      })
  return childrenWithProps
}
