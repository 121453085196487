import * as React from 'react'

import { useIsMobile } from 'Hooks'
import {

  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'Components'

const ResponsivePopoverContext = React.createContext({
  isDesktop: false
})

const useResponsivePopoverContext = () => {
  const context = React.useContext(ResponsivePopoverContext)
  if (!context) {
    throw new Error('ResponsivePopover components cannot be rendered outside the ResponsivePopover Context')
  }
  return context
}

const ResponsivePopover = ({ children, ...props }) => {
  const isDesktop = !useIsMobile()
  const ResponsivePopover = isDesktop ? Popover : Drawer

  return (
    <ResponsivePopoverContext.Provider value={{ isDesktop }}>
      <ResponsivePopover {...props} {...(!isDesktop && { autoFocus: true })}>
        {children}
      </ResponsivePopover>
    </ResponsivePopoverContext.Provider>
  )
}

const ResponsivePopoverTrigger = ({ className, children, ...props }) => {
  const { isDesktop } = useResponsivePopoverContext()
  const ResponsivePopoverTrigger = isDesktop ? PopoverTrigger : DrawerTrigger

  return (
    <ResponsivePopoverTrigger className={className} {...props}>
      {children}
    </ResponsivePopoverTrigger>
  )
}

const ResponsivePopoverContent = ({ className, children, ...props }) => {
  const { isDesktop } = useResponsivePopoverContext()
  const ResponsivePopoverContent = isDesktop ? PopoverContent : DrawerContent

  return (
    <ResponsivePopoverContent className={className} {...props}>
      {children}
    </ResponsivePopoverContent>
  )
}

const ResponsivePopoverHeader = ({ className, children, ...props }) => {
  const { isDesktop } = useResponsivePopoverContext()
  if (isDesktop) return null

  return (
    <DrawerHeader className={className} {...props}>
      {children}
    </DrawerHeader>
  )
}

const ResponsivePopoverTitle = ({ className, children, ...props }) => {
  const { isDesktop } = useResponsivePopoverContext()
  if (isDesktop) return null

  return (
    <DrawerTitle className={className} {...props}>
      {children}
    </DrawerTitle>
  )
}

const ResponsivePopoverDescription = ({ className, children, ...props }) => {
  const { isDesktop } = useResponsivePopoverContext()
  if (isDesktop) return null

  return (
    <DrawerDescription className={className} {...props}>
      {children}
    </DrawerDescription>
  )
}

export {
  ResponsivePopover,
  ResponsivePopoverTrigger,
  ResponsivePopoverContent,
  ResponsivePopoverHeader,
  ResponsivePopoverTitle,
  ResponsivePopoverDescription
}
