import { ProfileHeader, Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, T } from 'Components'
import { FiBriefcase, FiKey, FiList, FiMonitor, FiTrash2, FiUser } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { getRouteWithParams } from 'Utils'

const items = [
  { title: 'settings.profile.title', icon: FiUser, to: getRouteWithParams('/settings/account/profile') },
  { title: 'settings.companies.title', icon: FiBriefcase, to: getRouteWithParams('account.companies') },
  { title: 'settings.sessions.title', icon: FiMonitor, to: getRouteWithParams('account.sessions') },
  { title: 'settings.logins.title', icon: FiKey, to: getRouteWithParams('/settings/account/logins') },
  { title: 'Activitate', icon: FiList, to: getRouteWithParams('/settings/account/activity') }
]

export const AccountSettings = () => {
  const user = useSelector((state) => state.auth.session?.user)

  return (
    <>
      <ProfileHeader user={user} />

      <Section>
        {items.map(({ icon, to, title }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} />
            <SectionItemContent title={T(title)} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>

      <Section>
        <SectionItem to={getRouteWithParams('account.delete')} className={'text-destructive'}>
          <SectionItemHeader icon={FiTrash2} />
          <SectionItemContent title={'Dezactivează contul'} />
        </SectionItem>
      </Section>
    </>
  )
}
