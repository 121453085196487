import { callAPI, getRomanianCompanyByCUIQuery, searchRomanianCompaniesQuery, sendRomanianWebsiteContactFormQuery } from 'API'

export const getRomanianCompanyByCUI = async (cif, captchaToken) => {
  if (!cif) return null
  const { error, getRomanianCompanyByCUI: result } =
    (await callAPI({
      query: getRomanianCompanyByCUIQuery(),
      variables: { cif },
      allowPublic: true,
      captchaToken
    })) || {}
  return error ? { error } : result || null
}

export const searchRomanianCompanies = async (searchText, captchaToken) => {
  console.log('searchRomanianCompanies', searchText, captchaToken)
  if (!searchText || searchText.length < 3) return []
  const { error, searchRomanianCompanies: result } =
    (await callAPI({
      query: searchRomanianCompaniesQuery(),
      variables: { text: searchText },
      allowPublic: true,
      captchaToken
    })) || {}
  return error ? { error } : result || []
}

export const sendRomanianWebsiteContactForm = async (contactData, captchaToken) => {
  const { error } =
    (await callAPI({ query: sendRomanianWebsiteContactFormQuery(), variables: { contactData }, isPublic: true, captchaToken })) ||
    {}
  return error ? { error } : true
}
