import { Section, SectionDetails } from 'Components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export const EmployeeDetails = () => {
  let { userId } = useParams()

  const employees = useSelector((state) => state.auth.session?.account?.users)
  console.log('🚀 ~ EmployeeDetails ~ employees:', employees)

  const { userName, email, avatarUrl } = employees.find((employee) => employee.userId === userId)
  console.log('🚀 ~ EmployeeDetails ~ employees:', userName, email, avatarUrl)

  return (
    <>
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{userName}</h3>
        <p className="max-w-2xl text-sm/6 text-gray-500">{email}</p>
      </div>

      <Section>
        <SectionDetails items={[{ title: 'Date added', value: 'das' }]} />
      </Section>
    </>
  )
}
