import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import {
  Button,
  CompanyInvitationCard,
  Form,
  ResponsivePopover,
  ResponsivePopoverContent,
  ResponsivePopoverTrigger,
  Input,
  LoginLink,
  ResponsivePopoverTitle,
  ResponsivePopoverDescription,
  ResponsivePopoverHeader, SectionDetails, Section
} from 'Components'
import { useForm } from 'react-hook-form'
import { debounce } from 'lodash'
import { FiArrowLeft, FiCode, FiSearch } from 'react-icons/fi'

// the executeRecaptcha is passed from the parent component
export const AcceptCompanyForm = ({ onCloseForm }) => {
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)
  const invitation = useSelector((state) => state.auth.session.invitation)
  const form = useForm()

  const onSubmit = useCallback(async () => {
    await Actions.acceptInvitation()
    if (!errorAuth) onCloseForm?.()
  }, [errorAuth, onCloseForm])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <CompanyInvitationCard invitation={invitation} />
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        {errorAuth && <p className="mt-3 text-sm text-red-500">{errorAuth?.message}</p>}
        <div className="mt-4 flex items-center justify-between">
          <Button type="submit" isLoading={isLoadingAuth} className="mr-2 flex-1">
            Acceptați invitația
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const RegisterCompanyForm = ({ executeRecaptcha, hasLogin } = {}) => {
  const [searchResults, setSearchResults] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)

  const performSearch = useCallback(
    async (searchText) => {
      console.log(searchText)

      try {
        const captchaToken = await executeRecaptcha?.('searchRomanianCompanies')
        const companies = await Actions.searchRomanianCompanies(searchText, captchaToken)
        setSearchResults(companies)
      } catch (error) {
        console.error('Error searching companies:', error)
      }
    },
    [executeRecaptcha]
  )

  const debouncedSearch = debounce(performSearch, 300)

  // const onSubmit = useCallback(
  //   async ({ name: companyName, cif, city, county }) => {
  //     console.log('onSubmit', companyName, cif, city, county)
  //     if (companyName && cif) {
  //       const captchaToken = await executeRecaptcha?.('register')
  //       console.log('captchaToken', captchaToken)
  //
  //       await Actions.registerCompany({ companyName, cif, city, county, country: 'RO' }, captchaToken)
  //     }
  //   },
  //   [executeRecaptcha, onCloseForm]
  // )

  if (selectedCompany) return <CompanyConfirmationPage {...selectedCompany} setSelectedCompany={setSelectedCompany} />

  return (
    <div className="w-full max-w-lg space-y-6 text-center">
      <h1 className="text-2xl font-bold">Search your company</h1>

      <form className="flex flex-col">
        <ResponsivePopover>
          <ResponsivePopoverTrigger asChild>
            <Button role="combobox" variant="outline" size="lg" className="justify-between px-4">
              Select company
              <FiCode className="rotate-90" />
            </Button>
          </ResponsivePopoverTrigger>
          <ResponsivePopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
            <ResponsivePopoverHeader>
              <ResponsivePopoverTitle>Find your company</ResponsivePopoverTitle>
              <ResponsivePopoverDescription>Firmele cu care lucrezi</ResponsivePopoverDescription>
            </ResponsivePopoverHeader>
            <Input
              icon={FiSearch}
              onChange={(e) => debouncedSearch(e.target.value)}
              placeholder="Introduci codul fiscal fara RO sau denumirea"
              className="h-9"
            />
            <div className="max-h-96 overflow-scroll">
              {searchResults.map((company) => (
                <p key={company.cif} onClick={() => setSelectedCompany(company)}>
                  {company.name}
                  {/*<Check*/}
                  {/*  className={cn(*/}
                  {/*    "ml-auto",*/}
                  {/*    language.value === field.value*/}
                  {/*      ? "opacity-100"*/}
                  {/*      : "opacity-0"*/}
                  {/*  )}*/}
                  {/*/>*/}
                </p>
              ))}
            </div>
          </ResponsivePopoverContent>
        </ResponsivePopover>
      </form>

      {hasLogin && <LoginLink />}
    </div>
  )
}

const CompanyConfirmationPage = ({ cif, name, city, county, setSelectedCompany }) => {
  return (
    <div className="flex w-full max-w-lg flex-col space-y-6 max-md:h-full">
      <Button type="button" onClick={() => setSelectedCompany(null)} variant="outline" size="icon" className="rounded-full">
        <FiArrowLeft />
      </Button>
      <div>
        <h1 className="text-2xl font-bold">Are you sure?</h1>
      </div>

      <Section>
        <SectionDetails
          items={[
            { title: 'CIF', value: cif },
            { title: 'Name', value: name },
            { title: 'City', value: city },
            { title: 'County', value: county }
          ]}
        />
      </Section>

      <Button type="submit">Confirmă și Continuă</Button>
    </div>
  )
}
