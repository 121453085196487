import { Button, DateTime, Section, SectionDetails } from 'Components'
import { FiLogIn } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Actions } from 'Store'

export const CompanyDetails = () => {
  let { accountId: companyId } = useParams()

  const companies = useSelector((state) => state.auth.session?.accounts) || []
  const accountId = useSelector((state) => state.auth.session?.account?.accountId)
  const isActive = accountId === companyId

  const { companyName, city, cif, county, createdAt } = companies.find((company) => company.accountId === companyId) || {}

  const handleCompanyLogin = async () => {
    await Actions.selectUserAccount(companyId)
  }

  return (
    <div className="space-y-5">
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{companyName}</h3>
        {isActive && <p className="text-sm/6 text-gray-500">This company</p>}
      </div>

      <Section>
        <SectionDetails
          items={[
            { title: 'CIF', value: cif },
            { title: 'City', value: `${city}, ${county}` },
            { title: 'Creation date', value: <DateTime d={createdAt} /> }
          ]}
        />
      </Section>

      {!isActive && (
        <Button icon={FiLogIn} onClick={handleCompanyLogin}>
          Login
        </Button>
      )}
    </div>
  )
}
