import { Outlet } from 'react-router-dom'
import { useNotAuthenticatedRedirects } from 'Hooks'

export const NotAuthLayout = () => {
  useNotAuthenticatedRedirects()

  return (
    <div className="flex min-h-svh">
      <div className="relative isolate w-xl bg-teal-900 max-lg:hidden">
        <a href="https://factcurier.ro" className='m-10 inline-block'>
          <img src="/img/logo-dark.png" alt="Logo" width={250}  />
        </a>

        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
              width="200"
              height="200"
              x="50%"
              y="-1"
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none"></path>
            </pattern>
          </defs>
          <svg x="50%" y="-1" className="overflow-visible fill-teal-950/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth="0"
            ></path>
          </svg>
          <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"></rect>
        </svg>
      </div>

      <div className="bg-background flex grow flex-col items-center justify-center gap-6 p-6 md:p-10">
        <Outlet />
      </div>
    </div>
  )
}
