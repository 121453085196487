// this is the default locale and language
export const DEFAULT_LOCALE = 'ro-ro'
export const DEFAULT_LANGUAGE = DEFAULT_LOCALE.substring(0, 2)

// this is the default timezone
export const DEFAULT_TIMEZONE = 'Europe/Bucharest'

// this is the default currency
export const DEFAULT_CURRENCY = 'RON'
// make sure the default en-gb is always here
export const SUPPORTED_LOCALES = {
  english: ['en-gb', 'en-us'],
  română: ['ro-ro']
  // Italian: ['it-it'],
  // French: ['fr-fr', 'fr-be', 'fr-ca', 'fr-ch'],
  // Spanish: ['es-es', 'es-mx', 'es-us']
}

export const SUPPORTED_LANGUAGES = Object.keys(SUPPORTED_LOCALES).map((key) => ({
  code: SUPPORTED_LOCALES[key][0].substring(0, 2),
  name: key,
  locale: SUPPORTED_LOCALES[key][0]
}))

export const SUPPORTED_TIMEZONES = ['Europe/Bucharest', 'Europe/Rome', 'Europe/Berlin', 'UTC']
