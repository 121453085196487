import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Checkbox,
  Textarea,
  WithRecaptcha
} from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Actions } from 'Store'

const validationSchema = z.object({
  name: z.string().min(2, 'Numele trebuie să aibă cel puțin 2 caractere'),
  email: z.string().email('Adresă de e-mail invalidă'),
  message: z.string().min(10, 'Mesajul trebuie să aibă cel puțin 10 caractere'),
  wantNewsletter: z.boolean().default(false)
})

export const AdminContactForm = () => {
  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Contactează-ne</h1>
      </div>

      <WithRecaptcha>
        <ContactFormWithRecaptcha />
      </WithRecaptcha>
    </div>
  )
}

const ContactFormWithRecaptcha = ({ executeRecaptcha }) => {
  const isLoading = useSelector((state) => state.auth.isLoadingAuth)
  const error = useSelector((state) => state.auth.errorAuth)
  // Add state for success message
  const [isSuccess, setIsSuccess] = useState(false)

  const form = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      message: '',
      wantNewsletter: false
    }
  })

  const onSubmit = useCallback(
    async (data) => {
      const captchaToken = await executeRecaptcha?.('contact')
      const isSent = await Actions.sendRomanianWebsiteContactForm({ ...data, captchaToken })
      if (isSent) {
        form.reset()
        setIsSuccess(true)
        setTimeout(() => setIsSuccess(false), 5000)
      }
    },
    [executeRecaptcha, form]
  )

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4" noValidate>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nume</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mesaj</FormLabel>
              <FormControl>
                <Textarea {...field} rows={4} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="wantNewsletter"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2">
              <FormControl>
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <FormLabel className="text-sm">Doresc să primesc newsletter</FormLabel>
            </FormItem>
          )}
        />

        {error && <p className="text-sm text-red-500">{error?.message}</p>}
        {isSuccess && <p className="text-sm text-green-500">Mesajul a fost trimis cu succes! Vă mulțumim pentru contact.</p>}

        <Button type="submit" isLoading={isLoading} className="w-full">
          Trimite mesajul
        </Button>
      </form>
    </Form>
  )
}
