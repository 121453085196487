import { useSelector } from 'react-redux'
import {
  AcceptAppForm,
  AcceptCompanyForm,
  AppCard,
  AppInvitationCard,
  Card,
  CompanyCard,
  CompanyInvitationCard,
  EmailSentForm,
  LoginWithEmailForm,
  RegisterAppForm,
  RegisterCompanyForm,
  WithRecaptcha
} from 'Components'
import { Actions } from 'Store'
import { FiLogOut } from 'react-icons/fi'
import { getProduct } from 'Utils'
import { useLocation } from 'react-router-dom'

export const LoginPanel = ({ hasLinkedinLogin, hasGoogleLogin, hasAppleLogin, hasRegister }) => {
  const product = getProduct()
  const requestedEmail = useSelector((state) => state.auth.session.requestedEmail)
  const jwtId = useSelector((state) => state.auth.session.jwtId)
  const isResetVisible = jwtId || requestedEmail
  const user = useSelector((state) => state.auth.session.user)
  const { email, userName } = user || {}
  const invitation = useSelector((state) => state.auth.session.invitation)
   console.log('invitation',invitation)
  const account = useSelector((state) => state.auth.session.account)

  const isRegisterRoute = useLocation().pathname.startsWith('/register')

  const showLoginForm = !isRegisterRoute && !requestedEmail && !email
  const showEmailSent = !isRegisterRoute && requestedEmail && !email

  const showAppRegister = !invitation && !account && product === 'DEVPLATFORM' && (isRegisterRoute || userName)
  const showCompanyRegister = !invitation && !account && product === 'WEBAPP' && (isRegisterRoute || userName)
  const showAppAccept = invitation?.app && product === 'DEVPLATFORM' && userName && email
  const showCompanyAccept = invitation?.company && product === 'WEBAPP' && userName && email
  const showSomeCard = invitation || account || user
  const app = product === 'DEVPLATFORM' ? account : null
  const company = product === 'WEBAPP' ? account : null

  if (showLoginForm)
    return (
      <WithRecaptcha>
        <LoginWithEmailForm
          {...{ hasLinkedinLogin, hasGoogleLogin, hasAppleLogin }}
          hasRegister={hasRegister && !isResetVisible}
        />
      </WithRecaptcha>
    )

  if (showEmailSent)
    return (
      <WithRecaptcha>
        <EmailSentForm />
      </WithRecaptcha>
    )

  return (
    <>
      {isResetVisible && (
        <>
          <div className="absolute top-4 right-4">
            <button onClick={() => Actions.logout()} className="rounded-full p-2 hover:bg-gray-100" title="Logout">
              <FiLogOut className="h-6 w-6" />
            </button>
          </div>
          {showSomeCard && (
            <Card className="w-full max-w-md space-y-6 rounded-lg bg-white p-6 shadow-xl">
              {invitation?.app && !showAppAccept && <AppInvitationCard invitation={invitation} />}
              {invitation?.company && !showCompanyAccept && <CompanyInvitationCard invitation={invitation} />}
              {app && <AppCard app={app} />}
              {company && <CompanyCard company={company} />}
            </Card>
          )}
        </>
      )}

      {showAppRegister && <RegisterAppForm />}
      {showAppAccept && <AcceptAppForm />}
      {showCompanyRegister && (
        <WithRecaptcha>
          <RegisterCompanyForm hasLogin={!isResetVisible} />
        </WithRecaptcha>
      )}
      {showCompanyAccept && <AcceptCompanyForm />}
    </>
  )
}
