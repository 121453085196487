import { getAuthTokensFields } from 'API'

export const registerCompanyQuery = () => `
  mutation registerCompany($companyData: CompanyPayload!) {
    registerCompany(companyData: $companyData) ${getAuthTokensFields()}
  }`

export const selectUserAccountQuery = () => `
  mutation selectUserAccount($accountId: ID!) {
    selectUserAccount(accountId: $accountId) ${getAuthTokensFields()}
  }
`
