import { AcceptAppForm, RegisterAppForm } from 'Components'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const RegisterAppPanel = () => {
  const navigate = useNavigate()
  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      <RegisterAppForm onCloseForm={() => navigate('/')} />
    </div>
  )
}

export const AcceptAppPanel = () => {
  const navigate = useNavigate()
  const invitation = useSelector((state) => state.auth.session.invitation)
  useEffect(() => {
    if (!invitation) navigate('/')
  }, [invitation, navigate])
  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      <AcceptAppForm
        onCloseForm={async () => {
          navigate('/')
        }}
      />
    </div>
  )
}
