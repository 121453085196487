import { Avatar, AvatarFallback, AvatarImage, Button } from 'Components'
import { Fragment } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { cn, getInitialsByName } from 'Utils'

export const Section = ({ children, title, to, action, className, actionText = 'See all' }) => {
  const navigate = useNavigate()
  return (
    <div className={cn('mt-5', className)}>
      <div className="flex items-center justify-between">
        <p className="text-sm">{title}</p>
        {(action || to) && (
          <Button
            variant="link"
            onClick={() => (to ? navigate(to) : action())}
            // className="text-destructive"
          >
            {actionText}
          </Button>
        )}
      </div>
      <div className="bg-card mt-1 space-y-1 rounded-2xl p-1">{children}</div>
    </div>
  )
}

export const SectionItem = ({ children, to, className, onClick }) => {
  const classes = cn(
    'flex w-full items-center gap-x-4 rounded-xl px-3 py-4 has-data-[spacing=small]:p-2',
    (onClick || to) && 'hover:bg-sidebar-accent cursor-pointer',
    className
  )

  if (to)
    return (
      <Link to={to} className={classes}>
        {children}
      </Link>
    )

  if (onClick)
    return (
      <button onClick={onClick} className={classes}>
        {children}
      </button>
    )

  return <div className={classes}>{children}</div>
}

export const SectionItemHeader = ({ avatarUrl, alt, icon: Icon, hasBackground }) => {
  if (Icon) {
    if (hasBackground)
      return (
        <div data-spacing="small" className="bg-muted flex size-10 shrink-0 items-center justify-center rounded-full">
          <Icon size={20} />
        </div>
      )

    return <Icon size={20} className="shrink-0" />
  }

  return (
    <Avatar data-spacing="small">
      <AvatarImage src={avatarUrl} alt={alt} />
      <AvatarFallback>{getInitialsByName(alt)}</AvatarFallback>
    </Avatar>
  )
}

export const SectionItemContent = ({ title, description }) => {
  return (
    <div data-spacing={description && 'small'} className="grow text-left">
      <span className={cn({ 'text-sm/6 font-semibold': description })}>{title}</span>
      {description && <span className="text-muted-foreground block text-xs/5">{description}</span>}
    </div>
  )
}

export const SectionItemFooter = ({ icon: Icon, price }) => {
  if (price) return price
  if (Icon) return <Icon size={20} aria-hidden="true" />
  return <FiChevronRight size={20} className="text-gray-400" />
}

export const SectionDetails = ({ items = [], rightAlign = false }) => {
  return (
    <dl className="grid grid-cols-2 gap-4 p-2.5">
      {items.map(({ title, value }, i) => (
        <Fragment key={i}>
          <dt className="text-sm/6 font-medium">{title}</dt>
          <dd className={cn('text-muted-foreground text-sm/6 sm:mt-0', { 'text-right': rightAlign })}>{value}</dd>
        </Fragment>
      ))}
    </dl>
  )
}
