import { T } from 'Components'

export const Billing = () => {
  return (
    <div>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.billing.title" />
      </h3>
    </div>
  )
}
