import {
  DevHome,
  LoginPanel,
  TailwindIndicator,
  RegisterAppPanel,
  AppLayout,
  NotAuthLayout,
  DevSettingsLayout,
  DevSettings,
  DevProfileLayout,
  UserProfile,
  DevApps,
  AppDevelopers,
  Logout,
  Notifications,
  AppSessions,
  AcceptAppPanel,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs
} from 'Components'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

export const DevRouter = () => {
  const router = createBrowserRouter(
    [
      {
        path: '/whoami',
        element: <WhoAmI />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        element: <NotAuthLayout />,
        children: [
          {
            path: '/login',
            element: <LoginPanel hasLinkedinLogin hasRegister />
          },
          {
            path: '/register',
            element: <LoginPanel hasLinkedinLogin />
          }
        ]
      },
      {
        path: '/',
        element: <AppLayout />,
        children: [
          { index: true, element: <DevHome /> },
          {
            path: 'settings',
            element: <DevSettingsLayout />,
            children: [
              { index: true, element: <DevSettings /> },
              { path: 'team', element: <AppDevelopers /> },
              { path: 'logs', element: <EntityActivityLogs /> }
            ]
          },
          { path: 'notifications', element: <Notifications /> },
          {
            path: 'account',
            element: <DevProfileLayout />,
            children: [
              { index: true, element: <UserProfile /> },
              { path: 'sessions', element: <AppSessions /> },
              { path: 'apps', element: <DevApps /> },
              { path: 'logs', element: <UserActivityLogs /> }
            ]
          },
          {
            path: 'modals',
            children: [
              { path: 'app', element: <RegisterAppPanel /> },
              { path: 'invite', element: <AcceptAppPanel /> }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ],
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }
    }
  )

  return (
    <>
      <RouterProvider router={router} />
      <TailwindIndicator />
    </>
  )
}
