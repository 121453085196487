import { Tabs, TabsList, TabsTrigger } from 'Components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

export const AdminProfile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const activeTab = location.pathname === '/account' ? 'account' : location.pathname.split('/').pop()
  const handleTabChange = (value) => {
    navigate(value === 'account' ? '/account' : `/account/${value}`)
  }

  return (
    <div className="container mx-auto max-w-3xl p-6">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="mb-8">
          <TabsTrigger value="account">Profilul meu</TabsTrigger>
          <TabsTrigger value="sessions">Sesiuni active</TabsTrigger>
          <TabsTrigger value="logs">Activitate</TabsTrigger>
        </TabsList>
        <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
          <Outlet />
        </div>
      </Tabs>
    </div>
  )
}
