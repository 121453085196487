import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, T } from 'Components'

const invoices = [
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'domani' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'giovedi' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' }
]

export const ReceivedInvoices = () => {
  const dates = Object.groupBy(invoices, ({ date }) => date)
  return (
    <>
      <h1 className="pb-6 text-2xl font-semibold sm:tracking-tight">
        <T k="expenses.title" />
      </h1>

      {Object.entries(dates).map(([key, invoices]) => (
        <Section key={key} title={key}>
          {invoices?.map(({ id, amount, avatarUrl, companyName, date }, i) => (
            <SectionItem key={i} to={`/expenses/${id}`}>
              <SectionItemHeader avatarUrl={avatarUrl} alt={companyName} />
              <SectionItemContent title={companyName} description={date} />
              <SectionItemFooter price={amount} />
            </SectionItem>
          ))}
        </Section>
      ))}
    </>
  )
}
