import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
  DatePicker,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  InvoiceClientModal,
  Section,
  SectionDetails,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader,
  Separator
} from 'Components'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiEdit2, FiPlus, FiServer } from 'react-icons/fi'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getRouteWithParams } from 'Utils'
import { z } from 'zod'

const items = [{ name: 'dsadsad', icon: FiServer, quantity: 3, price: 50 }]

export const NewInvoice = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const clientId = searchParams.get('clientId')

  const [clientModalOpen, setClientModalOpen] = useState(!clientId)

  const saveInvoice = () => {
    navigate(getRouteWithParams('invoices.invoice', { invoiceId: 2 }))
  }

  return (
    <>
      <div className="grid gap-x-10 lg:grid-cols-3">
        <div className="lg:col-span-2">
          <Section>
            <InvoiceDetailsModal />
          </Section>

          <Section title="Client">
            {clientId ? (
              <SectionItem onClick={() => setClientModalOpen(true)}>
                <SectionItemHeader avatarUrl={''} alt={'FACTCURIER SRL'} />
                <SectionItemContent title={'FACTCURIER SRL'} description={'dsada'} />
                <SectionItemFooter icon={FiEdit2} />
              </SectionItem>
            ) : (
              <SectionItem onClick={() => setClientModalOpen(true)}>
                <SectionItemHeader icon={FiPlus} hasBackground />
                <SectionItemContent title={'Choose a client'} />
              </SectionItem>
            )}
          </Section>

          <Section title="Items">
            {items.map(({ icon, name, quantity, price }, i) => (
              <SectionItem key={i}>
                <SectionItemHeader icon={icon} hasBackground />
                <SectionItemContent description={quantity} title={name} />
                <SectionItemFooter price={price} />
              </SectionItem>
            ))}
            {/* <Separator /> */}
            <SectionItem>
              <SectionItemHeader icon={FiPlus} hasBackground />
              <SectionItemContent title={'Add item'} />
            </SectionItem>
          </Section>
        </div>

        <div>
          <Section>
            <SectionDetails
              rightAlign
              items={[
                { title: 'Total fara TVA', value: '1000 RON' },
                { title: 'TVA', value: '432 RON' }
              ]}
            />
            <Separator />
            <SectionDetails rightAlign items={[{ title: 'Total', value: '1432 RON' }]} />
          </Section>

          <div className="bg-background mt-2 py-3 max-lg:sticky max-lg:bottom-16">
            <Button onClick={saveInvoice} disabled={false} className="w-full">
              Continue
            </Button>
          </div>
        </div>
      </div>

      <InvoiceClientModal open={clientModalOpen} setOpen={setClientModalOpen} />
    </>
  )
}

const formSchema = z.object({ dob: z.date() })

const InvoiceDetailsModal = () => {
  const defaultValues = {}

  const form = useForm({ resolver: zodResolver(formSchema), defaultValues })

  const {
    formState: { isSubmitting }
  } = form

  function onSubmit(data) {
    console.log('🚀 ~ onSubmit ~ data:', data)
  }

  return (
    <Credenza>
      <CredenzaTrigger asChild>
        <button className="w-full">cdsaiao</button>
      </CredenzaTrigger>
      <CredenzaContent>
        <CredenzaHeader>
          <CredenzaTitle>Invoice details</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
              <FormField
                control={form.control}
                name="number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Number</FormLabel>
                    <Input field={field} />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date</FormLabel>
                    <DatePicker field={field} />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" isLoading={isSubmitting} className="w-full md:col-span-full">
                Update details
              </Button>
            </form>
          </Form>
        </CredenzaBody>
      </CredenzaContent>
    </Credenza>
  )
}
