import React, { useEffect, useState } from 'react'
import {
  Button,
  ListTable,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
  Input,
  Label,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from 'Components'

import { Controller, useForm } from 'react-hook-form'
import { Actions } from 'Store'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'

export const AdminAppsList = () => {
  const [selectedApp, setSelectedApp] = useState(null)
  const [sidebarMode, setSidebarMode] = useState(null) // 'view' or 'edit' only
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false)
  const [appToApprove, setAppToApprove] = useState(null)

  useEffect(() => {
    const syncLists = async () => {
      const adminApps = await Actions.getAdminApps()
      if (adminApps?.error) return
      Actions.populateAdminApps(adminApps)
    }
    syncLists()
  }, [])

  const { register, handleSubmit, control, setError, reset } = useForm()

  const handleEdit = (app) => {
    setSelectedApp(app)
    setSidebarMode('edit')
    setIsSheetOpen(true)
    reset({
      appUserId: app.appUserId,
      appName: app.appName,
      isApproved: app.isApproved
    })
  }

  const handleView = (app) => {
    setSelectedApp(app)
    setSidebarMode('view')
    setIsSheetOpen(true)
  }

  const handleSave = async (appData) => {
    const savedApp = await Actions.saveAdminApp(appData)
    if (savedApp?.error) {
      setError('appName', { type: 'manual', message: savedApp?.error?.message || 'Error updating app' })
    } else {
      Actions.upsertAdminApp(savedApp)
      setIsSheetOpen(false)
    }
  }

  const handleApproveClick = (app) => {
    setAppToApprove(app)
    setIsApproveDialogOpen(true)
  }

  const handleApproveConfirm = async (app) => {
    if (!app?.accountId) return
    const savedApp = await Actions.saveAdminApp({ ...app, isApproved: true })
    if (!savedApp?.error) Actions.upsertAdminApp(savedApp)
    setIsApproveDialogOpen(false)
    setAppToApprove(null)
    setIsSheetOpen(false)
  }

  return (
    <div className="space-y-6">
      <h2 className="text-foreground text-2xl font-bold">Apps</h2>
      <ListTable
        list="adminApps"
        columns={[
          { key: 'appUserId', label: 'User ID', type: 'string' },
          { key: 'accountId', label: 'App ID', type: 'string' },
          { key: 'appName', label: 'App Name', type: 'string' },
          { key: 'isApproved', label: 'Approved', type: 'boolean' }
        ]}
        isDesc
        sortKey="appName"
        actions={[
          { name: 'View', icon: 'FiEye', onAction: handleView },
          { name: 'Edit', icon: 'FiEdit', onAction: handleEdit },
          { name: 'Approve', icon: 'FiCheck', onAction: handleApproveClick }
        ]}
      />

      <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle className="text-foreground">{sidebarMode === 'edit' ? 'Edit App' : 'App Details'}</SheetTitle>
          </SheetHeader>
          {sidebarMode === 'view' && (
            <div className="space-y-4">
              <p className="text-foreground">
                <strong>App User ID:</strong> {selectedApp.appUserId}
              </p>
              <p className="text-foreground">
                <strong>App ID:</strong> {selectedApp.accountId}
              </p>
              <p className="text-foreground">
                <strong>App Name:</strong> {selectedApp.appName}
              </p>
              <p className="text-foreground flex items-center">
                <strong className="mr-2">Approved:</strong>
                {selectedApp.isApproved ? (
                  <FiCheckCircle className="text-accepted h-5 w-5" />
                ) : (
                  <FiXCircle className="text-destructive h-5 w-5" />
                )}
              </p>
              <SheetFooter>
                <Button variant="outline" onClick={() => setSidebarMode('edit')}>
                  Edit
                </Button>
              </SheetFooter>
            </div>
          )}
          {sidebarMode === 'edit' && (
            <form onSubmit={handleSubmit(handleSave)} className="space-y-4">
              <div className="space-y-1">
                <Label htmlFor="appName" className="text-foreground">
                  App Name
                </Label>
                <Input id="appName" {...register('appName')} readOnly className="bg-muted text-foreground w-full" />
              </div>
              <div className="flex items-center space-x-2">
                <Controller
                  name="isApproved"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="isApproved"
                      type="checkbox"
                      checked={field.value}
                      onChange={field.onChange}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  )}
                />
                <Label htmlFor="isApproved">Approved</Label>
              </div>
              <SheetFooter>
                <Button type="submit">Save</Button>
              </SheetFooter>
            </form>
          )}
        </SheetContent>
      </Sheet>

      <Dialog open={isApproveDialogOpen} onOpenChange={setIsApproveDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Approval</DialogTitle>
            <DialogDescription>Are you sure you want to approve the app "{appToApprove?.appName}"?</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsApproveDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="default" onClick={() => handleApproveConfirm(appToApprove)}>
              Approve
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
