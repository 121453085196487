// EntityActivityTimeline.js
import React, { useEffect } from 'react'
import { ActivityLogItem, T } from 'Components'
import { Actions } from 'Store'
import { useList } from 'Hooks'

export const EntityActivityLogs = () => {
  const logs = useList('entityActivityLogs', 'ts', true)
  useEffect(() => {
    const syncList = async () => {
      const entityActivityLogs = await Actions.getEntityActivityLogs()
      if (entityActivityLogs?.error) return
      Actions.populateEntityActivityLogs(entityActivityLogs)
    }
    syncList()
  }, [])

  return (
    <>
      <h1 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.logs.title" />
      </h1>

      <div className="flow-root">
        <ul className="-mb-8">
          {logs.map((log, i) => (
            <li key={i}>
              <div className="relative pb-8">
                {i !== logs.length - 1 ? (
                  <span aria-hidden="true" className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  <ActivityLogItem {...log} />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
