import { useEffect } from 'react'
import { RegisterCompanyForm, AcceptCompanyForm } from '../auth/RegisterCompanyForm'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MainModal, WithRecaptcha } from 'Components'

export const RegisterCompanyPanel = () => {
  return (
    <div className='w-full flex items-center justify-center'>
      <WithRecaptcha>
        <RegisterCompanyForm />
      </WithRecaptcha>
    </div>
  )
}

export const AcceptCompanyPanel = () => {
  const navigate = useNavigate()
  const invitation = useSelector((state) => state.auth.session.invitation)
  useEffect(() => {
    if (!invitation) navigate('/')
  }, [invitation, navigate])

  return (
    <MainModal>
      <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
        <AcceptCompanyForm
          onCloseForm={async () => {
            navigate('/')
          }}
        />
      </div>
    </MainModal>
  )
}
