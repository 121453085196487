import { useSelector } from 'react-redux'
import { Button, Input, Form, FormField, FormItem, FormLabel, FormControl, FormMessage, T } from 'Components'
import { Actions } from 'Store'
import { toast } from 'sonner'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const accountFormSchema = z.object({
  userName: z
    .string()
    .trim()
    .min(2, { message: 'Name must be at least 2 characters.' })
    .max(30, { message: 'Name must not be longer than 30 characters.' }),
  userSurname: z.string().trim()
})

export const UserProfile = () => {
  const user = useSelector((state) => state.auth.session?.user)
  const defaultValues = { userName: user?.userName || '', userSurname: user?.userSurname || '' }
  const form = useForm({ resolver: zodResolver(accountFormSchema), defaultValues })

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting }
  } = form

  const onSubmit = async (data) => {
    await Actions.saveUserProfile(data)
    toast('Successfully Updated')
    reset(data, { keepValues: true })
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <h1 className="text-2xl font-semibold sm:tracking-tight">
          <T k="settings.personalDetails.title" />
        </h1>

        {/* Name Section */}
        <FormField
          control={control}
          name="userName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <T k="settings.personalDetails.name" />
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="userSurname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <T k="settings.personalDetails.surname" />
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button disabled={!isDirty} isLoading={isSubmitting} type="submit">
          <T k="settings.save" />
        </Button>
      </form>
    </Form>
  )
}
