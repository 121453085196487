import {
  FiHome,
  FiInbox,
  FiSend,
  FiPackage,
  FiArchive,
  FiShoppingBag,
  FiCode,
  FiActivity,
  FiClock,
  FiUsers,
  FiMessageSquare,
  FiGrid,
  FiBell,
  FiSettings,
  FiUser,
  FiLogOut,
  FiTool,
  FiPlus,
  FiGlobe,
  FiSun
} from 'react-icons/fi'

export const APP_SETTINGS_ITEMS = [
  {
    items: [
      { name: 'settings.account.title', icon: FiUser, to: 'account' },
      { name: 'settings.notifications.title', icon: FiBell, to: 'account.notifications' },
      {
        name: 'settings.preferences.title',
        icon: FiTool,
        to: 'account.preferences',
        items: [
          { name: 'settings.language.title', icon: FiGlobe, to: 'account.preferences.language' },
          { name: 'settings.theme.title', icon: FiSun, to: 'account.preferences.theme' },
          { name: 'Timezone', icon: FiClock, to: 'account.preferences.timezone' }
        ]
      }
    ]
  },
  {
    category: 'Company',
    items: [
      { name: 'settings.company.title', icon: FiShoppingBag, to: 'settings.company' },
      { name: 'settings.spv.title', icon: FiShoppingBag, to: 'settings.company.spv' },
      { name: 'settings.billing.title', icon: FiBell, to: 'settings.company.billing' },
      { name: 'settings.team.title', icon: FiUsers, to: 'settings.company.team' },
      { name: 'settings.logs.title', icon: FiShoppingBag, to: 'settings.company.logs' },
      { name: 'settings.apps.title', icon: FiGrid, to: 'settings.company.apps' }
    ]
  },
  { mobileOnly: true, items: [{ name: 'home.logout', icon: FiLogOut, to: 'logout' }] }
]

export const APP_SIDEBAR_ITEMS = [
  {
    title: 'Achiziții',
    items: [
      { title: 'expenses.title', to: 'expenses', icon: FiInbox, count: 24, actions: [] },
      { title: 'nir.title', to: 'nir', icon: FiPackage }
    ]
  },
  {
    title: 'Vânzări',
    items: [
      { title: 'invoices.title', to: 'invoices', icon: FiSend, actions: [] },
      { title: 'clients.title', to: 'clients', icon: FiUsers },
      { title: 'products.title', to: 'products', icon: FiArchive }
    ]
  },
  { title: 'Apps', items: [{ title: 'Whatsapp', to: 'apps.whatsapp', icon: FiMessageSquare }] }
]

export const DEV_SIDEBAR_ITEMS = [
  { title: '', hidden: false, items: [{ id: 'dashboard', title: 'Dashboard', to: '/', icon: FiHome }] },
  {
    id: 'apps',
    title: 'Applicatii',
    hidden: true,
    items: [{ id: 'apps', title: 'Lista aplicatii', to: '/apps', icon: FiInbox }]
  }
]

export const ADMIN_SIDEBAR_ITEMS = [
  {
    id: 'dashboard',
    title: 'Panou de bord',
    hidden: false,
    items: [
      { id: 'dashboard', title: 'Dashboard', to: '/', icon: FiHome },
      { id: 'status', title: 'Status', to: '/status', icon: FiActivity },
      { id: 'sessions', title: 'Sesiuni', to: '/sessions', icon: FiClock },
      { id: 'users', title: 'Utilizatori', to: '/users', icon: FiUsers }
    ]
  },
  {
    id: 'managers',
    title: 'Manageri',
    hidden: false,
    items: [{ id: 'admusr', title: 'Lista useri', to: '/admin-users', icon: FiInbox }]
  },
  {
    id: 'apps',
    title: 'Aplicatii',
    hidden: false,
    items: [{ id: 'apps', title: 'Lista aplicatii', to: '/apps', icon: FiCode }]
  }
]

export const APP_MOBILE_ITEMS = [
  { title: 'dashboard.title', to: 'home', icon: FiHome },
  { title: 'client.title', to: 'clients', icon: FiUsers },
  { title: 'Create invoice', to: 'invoices.create', icon: FiPlus },
  { title: 'invoices.title', to: 'invoices', icon: FiSend },
  { title: 'settings.settings.title', to: 'settings', icon: FiSettings }
]

export const DEV_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/', icon: FiHome },
  { id: 'apps', title: 'Applicatii', to: '/apps', icon: FiInbox }
]

export const ADMIN_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/', icon: FiHome },
  { id: 'managers', title: 'Manageri', to: '/admin-users', icon: FiInbox }
]

export const APP_ROUTES = {
  home: '/',
  logout: '/logout',
  login: '/login',
  register: '/register',
  settings: '/settings',
  'companies.new': '/companies/new',
  invoices: '/invoices',
  'invoices.invoice': '/invoices/{invoiceId}',
  'invoices.create': '/invoices/new',
  expenses: '/expenses',
  products: '/products',
  notifications: '/notifications',
  nir: '/nir',
  clients: '/clients',
  'clients.client': '/clients/{clientId}',
  'client.delete': '/clients/{clientId}/delete',
  'settings.company': '/settings/company',
  'settings.company.spv': '/settings/spv',
  'settings.company.billing': '/settings/billing',
  'settings.company.team': '/settings/team',
  'settings.company.team.employee': '/settings/team/{userId}',
  'settings.company.logs': '/settings/logs',
  'settings.company.apps': '/settings/apps',
  account: '/settings/account',
  'account.delete': '/settings/account/delete',
  'account.notifications': '/settings/notifications',
  'account.notifications.push': '/settings/notifications/push',
  'account.notifications.email': '/settings/notifications/email',
  'account.preferences': '/settings/preferences',
  'account.preferences.language': '/settings/preferences/language',
  'account.preferences.theme': '/settings/preferences/theme',
  'account.preferences.timezone': '/settings/preferences/timezone',
  'account.sessions': '/settings/account/sessions',
  'account.sessions.session': '/settings/account/sessions/{sessionId}',
  'account.companies': '/settings/account/companies',
  'account.companies.company': '/settings/account/companies/{accountId}',
  'apps.whatsapp': '/apps/whatsapp'
}
