import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  ListTable,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
  Input,
  MultiSelect,
  Label
} from 'Components'

import { Controller, useForm } from 'react-hook-form'
import { Actions } from 'Store'
import { FiPlus, FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { randomString } from 'Utils'

export const AdminUsersList = () => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [sidebarMode, setSidebarMode] = useState(null) // 'view', 'edit', or 'create'
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [relodId, setRelodId] = useState(null)
  const adminUsersList = useSelector((state) => state.auth.session?.account?.users)
  const invitationsList = useSelector((state) => state.auth.session?.account?.invitations)
  useEffect(() => {
    if (adminUsersList?.length > 0) {
      Actions.populateList({
        list: 'adminUsers',
        items: (adminUsersList || []).concat(
          (invitationsList || []).map((invitation) => ({ ...invitation, userId: invitation.jwtId, isInvitation: true }))
        ),
        idKey: 'userId'
      })
    }
  }, [adminUsersList, invitationsList])

  useEffect(() => {
    if (relodId) {
      console.log('relodId', relodId)
      Actions.getMe()
    }
  }, [invitationsList, relodId])

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    reset
  } = useForm()

  const roleOptions = ['ADMIN', 'MANAGER', 'VIEWER', 'TECHNOLOGIST']

  const handleEdit = (user) => {
    setSelectedUser(user)
    setSidebarMode('edit')
    setIsSheetOpen(true)
    reset({
      userName: user.userName,
      email: user.email,
      roles: user.roles || [],
      isLimited: user.isLimited
    })
  }

  const handleView = (user) => {
    setSelectedUser(user)
    setSidebarMode('view')
    setIsSheetOpen(true)
  }

  const handleCreateUser = () => {
    setSelectedUser(null)
    setSidebarMode('create')
    setIsSheetOpen(true)
    reset({
      userName: '',
      email: '',
      roles: [],
      isLimited: false
    })
  }

  const handleDeleteClick = (user) => {
    setUserToDelete(user)
    setIsDeleteDialogOpen(true)
  }

  const handleSave = async (userData) => {
    if (sidebarMode === 'create') {
      // Handle creating new user
      console.warn('Creating new user:', userData)
      // Implement the creation logic here
    } else {
      // Handle updating existing user
      console.warn('Updating user:', userData)
      // Implement the update logic here
    }
    if (userData.isOwner) {
      // add role OWNER if not there
      userData.roles = [...userData.roles, 'OWNER']
    } else {
      // remove role OWNER if there
      userData.roles = userData.roles.filter((role) => role !== 'OWNER')
    }

    delete userData.isOwner
    const result = await Actions.inviteUserAccount({ ...userData })

    if (!result) {
      setError('email', { type: 'manual', message: 'Error sending invite' })
    } else {
      setRelodId(randomString())
      setIsSheetOpen(false)
    }
  }

  const handleDeleteConfirm = async (user) => {
    if (!user?.email) return
    const usrDel = await Actions.deleteAdminUser(user.email)
    if (!usrDel?.error) Actions.deleteAdminUser(user.email)
    // setUsers(users.filter((user) => user.userId !== userToDelete.userId))
    setIsDeleteDialogOpen(false)
    setUserToDelete(null)
    setIsSheetOpen(false)
  }
  //
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Access Control utilizatori platforma de administrare</h2>
      <Button onClick={handleCreateUser}>
        <FiPlus className="h-4 w-4" />
      </Button>
      <ListTable
        list="adminUsers"
        idKey="userId"
        columns={[
          { key: 'avatarUrl', label: 'Avatar', type: 'avatar' },
          { key: 'userName', label: 'Username', type: 'string' },
          { key: 'email', label: 'Email', type: 'string' },
          { key: 'roles', label: 'Roles', type: 'multiSelect' },
          { key: 'lastLoginAt', label: 'Last Login', type: 'date' },
          { key: 'isLimited', label: 'Limited', type: 'boolean' }
        ]}
        isDesc
        sortKey="lastLoginAt"
        actions={[
          { name: 'View', icon: 'FiEye', onAction: handleView },
          { name: 'Edit', icon: 'FiEdit', onAction: handleEdit },
          { name: 'Delete', icon: 'FiTrash', onAction: handleDeleteClick }
        ]}
      />

      <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>
              {sidebarMode === 'create' ? 'Invite Admin User' : sidebarMode === 'edit' ? 'Edit User' : 'User Details'}
            </SheetTitle>
          </SheetHeader>
          {sidebarMode === 'view' && (
            <div className="space-y-4">
              <p>
                <strong>Full name:</strong> {selectedUser.userName}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser.email}
              </p>

              <p>
                <strong>Roles:</strong> {selectedUser.roles.join(', ')}
              </p>

              <p className="flex items-center">
                <strong className="mr-2">Last Login:</strong>
                {selectedUser.lastLoginAt ? selectedUser.lastLoginAt : 'Not confirmed'}
              </p>
              <p className="flex items-center">
                <strong className="mr-2">Blocked:</strong>
                {selectedUser.isLimited ? (
                  <FiCheckCircle className="h-5 w-5 text-green-500" />
                ) : (
                  <FiXCircle className="h-5 w-5 text-red-500" />
                )}
              </p>
              <SheetFooter>
                <Button variant="outline" onClick={() => setSidebarMode('edit')}>
                  Edit
                </Button>
                <Button variant="destructive" onClick={() => setIsDeleteDialogOpen(true)}>
                  Delete
                </Button>
              </SheetFooter>
            </div>
          )}
          {(sidebarMode === 'edit' || sidebarMode === 'create') && (
            <form onSubmit={handleSubmit(handleSave)} className="space-y-4">
              <div className="space-y-1">
                <Label htmlFor="userName" className="text-sm font-medium text-gray-700">
                  Full name
                </Label>
                <Input id="userName" {...register('userName', { required: 'Name is required' })} className="w-full" />
                {errors.userName && <p className="text-xs text-red-500">{errors.userName.message}</p>}
              </div>
              <div className="space-y-1">
                <Label htmlFor="email" className="text-sm font-medium text-gray-700">
                  Email
                </Label>
                {sidebarMode === 'create' ? (
                  <Input
                    id="email"
                    type="email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    className="w-full"
                  />
                ) : (
                  <Input id="email" {...register('email')} readOnly className="w-full bg-gray-100" />
                )}
                {errors.email && <p className="text-xs text-red-500">{errors.email.message}</p>}
              </div>
              <div className="space-y-1">
                <Label htmlFor="roles" className="text-sm font-medium text-gray-700">
                  Roles
                </Label>

                <Controller
                  name="roles"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => <MultiSelect options={roleOptions} onChange={field.onChange} value={field.value} />}
                />
                {errors.roles && <p className="text-xs text-red-500">{errors.roles.message}</p>}
              </div>

              <div className="flex items-center space-x-2">
                {selectedUser?.lastLoginAt ? selectedUser?.lastLoginAt : 'Not confirmed'}
                <Label htmlFor="lastLoginAt" className="text-sm font-medium text-gray-700">
                  Ultima logare
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Controller
                  name="isLimited"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="isLimited"
                      type="checkbox"
                      checked={field.value}
                      onChange={field.onChange}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  )}
                />
                <Label htmlFor="isLimited" className="text-sm font-medium text-gray-700">
                  Blocked
                </Label>
              </div>
              <SheetFooter>
                <Button
                  type="submit"
                  className="w-full rounded-md bg-blue-600 px-4 py-2 text-white transition duration-300 hover:bg-blue-700"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit((data) => {
                      if (Object.keys(errors).length === 0) {
                        handleSave(data)
                        setIsSheetOpen(false)
                      }
                    })()
                  }}
                >
                  Save
                </Button>
              </SheetFooter>
            </form>
          )}
        </SheetContent>
      </Sheet>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete the user "{userToDelete?.userName}"? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={() => handleDeleteConfirm(userToDelete)}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
