export const DEV_ROUTES = {
  home: '/',
  whoami: '/whoami',
  logout: '/logout',
  login: '/login',
  register: '/register',
  settings: '/settings',
  'settings.team': '/settings/team',
  'settings.logs': '/settings/logs',
  notifications: '/notifications',
  account: '/account',
  'account.sessions': '/account/sessions',
  'account.apps': '/account/apps',
  'account.logs': '/account/logs',
  'modals.app': '/modals/app',
  'modals.invite': '/modals/invite',
  invoices: '/invoices',
  'invoices.invoice': '/invoices/{invoiceId}',
  'invoices.create': '/invoices/new',
  expenses: '/expenses',
  products: '/products',
  nir: '/nir',
  clients: '/clients',
  'clients.client': '/clients/{clientId}',
  'client.delete': '/clients/{clientId}/delete',
  'settings.company': '/settings/company',
  'settings.company.spv': '/settings/spv',
  'settings.company.billing': '/settings/billing',
  'settings.company.team': '/settings/team',
  'settings.company.team.employee': '/settings/team/{userId}',
  'settings.company.logs': '/settings/logs',
  'settings.company.apps': '/settings/apps',
  'account.delete': '/settings/account/delete',
  'account.notifications': '/settings/notifications',
  'account.notifications.push': '/settings/notifications/push',
  'account.notifications.email': '/settings/notifications/email',
  'account.preferences': '/settings/preferences',
  'account.preferences.language': '/settings/preferences/language',
  'account.preferences.theme': '/settings/preferences/theme',
  'account.preferences.timezone': '/settings/preferences/timezone',
  'account.companies': '/settings/account/companies',
  'account.companies.company': '/settings/account/companies/{accountId}',
  'apps.whatsapp': '/apps/whatsapp'
}
