import { FaSignInAlt, FaUserShield } from 'react-icons/fa'
import { booleanChanges, renderRolesChanges } from './elems'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getInitialsByName, getRouteWithParams } from 'Utils'
import { Avatar, AvatarFallback, AvatarImage } from 'Components'

export const CompanyCreatedActivity = ({ data, caller }) => (
  <>
    <Avatar className="ring-8 ring-white">
      <AvatarImage src={caller.avatarUrl} alt={caller.name} />
      <AvatarFallback>{getInitialsByName(caller.name)}</AvatarFallback>
    </Avatar>
    <div className="min-w-0 flex-1">
      <div>
        <div className="text-sm">
          You created <span className="font-medium text-gray-900">{data.companyName}</span>
        </div>
        <p className="mt-0.5 text-sm text-gray-500">{data.cif}</p>
      </div>
    </div>
  </>
)

export const CompanyUpdatedActivity = ({ data }) => (
  <div className="space-y-1 text-sm text-gray-500 dark:text-gray-300">
    {Object.entries(data).map(([key, [oldValue, newValue]]) => (
      <div key={key} className="flex items-center gap-2">
        <span className="font-medium">{key}:</span>
        <span className="text-red-500 line-through dark:text-red-400">{oldValue}</span>
        <span className="text-green-500 dark:text-green-400">{newValue}</span>
      </div>
    ))}
  </div>
)

export const CompanyDeletedActivity = ({ data }) => {
  return (
    <div className="text-sm text-red-500 dark:text-red-400">
      Companie ștearsă
      <div className="text-muted-foreground">
        {data.companyName} #{data.cif}
        {data.isVerified && (
          <span className="ml-2 rounded bg-green-100 px-2.5 py-0.5 text-xs text-green-600 dark:bg-green-900 dark:text-green-300">
            Verificată
          </span>
        )}
      </div>
    </div>
  )
}

export const AppCreatedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="font-medium">Aplicație nouă creată</div>
    <div className="text-muted-foreground">
      #{data.accountId} {data.appName}
    </div>
  </div>
)

export const AppUpdatedActivity = ({ data }) => (
  <div className="space-y-1 text-sm text-gray-500 dark:text-gray-300">
    {Object.entries(data).map(([key, [oldValue, newValue]]) => (
      <div key={key} className="flex items-center gap-2">
        <span className="font-medium">{key}:</span>
        <span className="text-red-500 line-through dark:text-red-400">{oldValue}</span>
        <span className="text-green-500 dark:text-green-400">{newValue}</span>
      </div>
    ))}
  </div>
)

export const AppDeletedActivity = ({ data }) => (
  <div className="text-sm text-red-500 dark:text-red-400">
    Aplicație ștearsă
    <div className="text-muted-foreground">
      #{data.accountId} {data.appName}
    </div>
  </div>
)

export const UserLinkedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="text-muted-foreground mb-2 text-xs">#{data.userId} adăugat</div>
    <div className="font-medium">{data.userName}</div>
    <div className="text-muted-foreground">({data.email})</div>
    {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
    {data.providers.length > 0 && (
      <div className="mt-2 flex gap-2">
        {data.providers.includes('apple') && (
          <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>
        )}
        {data.providers.includes('google') && (
          <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>
        )}
        {data.providers.includes('linkedin') && (
          <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>
        )}
      </div>
    )}
    <div className="mb-2 flex gap-2">
      {data.roles.map((role) => (
        <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
          {role}
        </span>
      ))}
    </div>
    <div className="text-muted-foreground text-xs">
      User ID: {data.userId}
      {data.isLimited && (
        <span className="ml-2 rounded bg-red-100 px-2.5 py-0.5 text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  </div>
)

export const UserPermissionsUpdatedActivity = ({ data }) => {
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300">
      <div className="text-muted-foreground mb-2 text-xs">#{data.userId} a actualizat permisiunile</div>
      <div className="font-medium">{data.userName}</div>
      <div className="text-muted-foreground">({data.email})</div>
      {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
      {data.providers.length > 0 && (
        <div className="mt-2 flex gap-2">
          {data.providers.includes('apple') && (
            <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>
          )}
          {data.providers.includes('google') && (
            <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>
          )}
          {data.providers.includes('linkedin') && (
            <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>
          )}
        </div>
      )}
      <div className="space-y-1">
        {data.changes.roles && (
          <div className="flex flex-col gap-1">
            <span className="text-muted-foreground text-xs">Roluri actualizate:</span>
            {renderRolesChanges(data.changes.roles[0], data.changes.roles[1])}
          </div>
        )}
        {data.changes.isLimited && booleanChanges(data.changes.isLimited, FaUserShield, ['Blocat', 'Deblocat'])}
      </div>
    </div>
  )
}

export const UserUnlinkedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="text-muted-foreground">#{data.userId} deconectat</div>
    <div className="font-medium">{data.userName}</div>
    <div className="text-muted-foreground">({data.email})</div>
    {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
    {data.providers.length > 0 && (
      <div className="mt-2 flex gap-2">
        {data.providers.includes('apple') && (
          <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>
        )}
        {data.providers.includes('google') && (
          <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>
        )}
        {data.providers.includes('linkedin') && (
          <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>
        )}
      </div>
    )}
    <div className="mb-2 flex gap-2">
      {data.roles.map((role) => (
        <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
          {role}
        </span>
      ))}
    </div>
    <div className="text-muted-foreground text-xs">
      {data.isLimited && (
        <span className="ml-2 rounded bg-red-100 px-2.5 py-0.5 text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  </div>
)

export const UserLoginActivity = ({ data }) => {
  const userId = useSelector((state) => state.auth.session?.userId)
  const companyName = useSelector((state) => state.auth.session?.account?.companyName)
  const userName = data.userId === userId ? 'You' : data.userName
  return (
    <>
      <ActivityLogItemHeader icon={FaSignInAlt} />
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <Link
            to={getRouteWithParams('settings.company.team.employee', { userId: data.userId })}
            className="font-medium text-gray-900"
          >
            {userName}
          </Link>{' '}
          s-a logat <span className="font-medium text-gray-900">{companyName}</span>{' '}
          {/* <span className="whitespace-nowrap">{activityItem.date}</span> */}
        </div>
      </div>
    </>
  )
}

const ActivityLogItemHeader = ({ icon: Icon }) => (
  <div className="relative px-1">
    <div className="flex size-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
      <Icon aria-hidden="true" className="size-3.5 text-gray-500" />
    </div>
  </div>
)

export const ActivityLogItem = ({ type, data, caller }) => {
  // if (!caller?.name) return
  if (type === 'COMPANY_CREATED') return <CompanyCreatedActivity data={data} caller={caller} />
  if (type === 'COMPANY_UPDATED') return <CompanyUpdatedActivity data={data} />
  if (type === 'COMPANY_DELETED') return <CompanyDeletedActivity />
  if (type === 'APP_CREATED') return <AppCreatedActivity data={data} />
  if (type === 'APP_UPDATED') return <AppUpdatedActivity data={data} />
  if (type === 'APP_DELETED') return <AppDeletedActivity />
  if (type === 'USER_LINKED') return <UserLinkedActivity data={data} />
  if (type === 'USER_PERMISSIONS_UPDATED') return <UserPermissionsUpdatedActivity data={data} />
  if (type === 'USER_UNLINKED') return <UserUnlinkedActivity data={data} />
  if (type === 'USER_LOGIN') return <UserLoginActivity data={data} />
}
