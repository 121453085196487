import { useSelector } from 'react-redux'
import { getProduct, getProductName } from 'Utils'

export const useApp = () => {
  const appState = useSelector((state) => state.app)
  const isLoadingLocale = useSelector((state) => state.locales.isLoadingLocale)
  const isAuth = useSelector((state) => state.auth.session.userId && state.auth.session.accountId)
  const isAuthLoading = useSelector((state) => state.auth.isLoadingAuth)
  const isLoading = appState.isLoadingApp || isLoadingLocale || (!isAuth && isAuthLoading)
  const product = getProduct().toLowerCase()
  const productName = getProductName()
  return { ...appState, isLoading, product, productName }
}
