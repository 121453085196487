import { useEffect, StrictMode } from 'react'
import { Actions, State, Store } from 'Store'
import { IS_DEBUG } from 'Constants'
import * as Constants from 'Constants'
import * as Hooks from 'Hooks'
import * as Components from 'Components'
import * as Utils from 'Utils'

// the debug provider well print some info to the console
export const DebugProvider = ({ children }) => {
  useEffect(() => {
    if (IS_DEBUG) {
      window.State = () => State()
      window.Actions = Actions
      window.Utils = Utils
      window.Constants = Constants
      window.Hooks = Hooks
      window.Components = Components
      window.Store = Store
    }
  }, [])

  const isDebug = IS_DEBUG && process.env.REACT_STRICT_MODE === 'true'
  return isDebug ? <StrictMode>{children}</StrictMode> : children
}
