import { Actions } from 'Store'
import { getTimeUntilExpiry } from 'Utils'
import { FiCheck, FiX } from 'react-icons/fi'

export const AppApprovalNotification = ({ accountId, appName, createdAt, expiresAt, invitedByName, invitedByEmail }) => (
  <div className="flex w-full items-center gap-3 py-2">
    <div className="min-w-0 flex-1 space-y-2">
      <div className="space-y-1.5">
        <p className="text-sm leading-tight">
          Ai fost invitat de <span className="font-medium">{invitedByName}</span> cu emailul{' '}
          <span className="text-muted-foreground">{invitedByEmail}</span> să lucrezi la aplicația
        </p>
        <p className="flex items-baseline gap-2">
          <span className="text-muted-foreground text-xs font-medium">#{accountId}</span>
          <span className="text-primary text-lg font-medium">{appName}</span>
        </p>
      </div>
      {createdAt || expiresAt ? (
        <div className="flex items-center gap-3 text-xs">
          <span className="text-muted-foreground">
            {new Date(createdAt).toLocaleDateString('ro-RO', {
              hour: '2-digit',
              minute: '2-digit'
            })}
          </span>
          {expiresAt && (
            <span className="text-destructive flex items-center gap-1 font-medium">
              <span className="bg-destructive/50 h-1 w-1 rounded-full" />
              Expiră în {getTimeUntilExpiry(expiresAt)}
            </span>
          )}
        </div>
      ) : null}
    </div>
    <div className="flex shrink-0 gap-2">
      <button
        onClick={() => Actions.approveOrDenyApp({ accountId, isApproved: true })}
        className="group hover:bg-success/10 active:bg-success/20 rounded-md p-1.5 transition-all duration-200"
        title="Acceptă invitația"
      >
        <FiCheck size={18} className="text-muted-foreground group-hover:text-success stroke-[2.5px] transition-colors" />
      </button>
      <button
        onClick={() => Actions.approveOrDenyApp({ accountId, isApproved: false })}
        className="group hover:bg-destructive/10 active:bg-destructive/20 rounded-md p-1.5 transition-all duration-200"
        title="Refuză invitația"
      >
        <FiX size={18} className="text-muted-foreground group-hover:text-destructive stroke-[2.5px] transition-colors" />
      </button>
    </div>
  </div>
)
