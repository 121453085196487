import { cn } from 'Utils'
import { Button, Popover, PopoverContent, PopoverTrigger } from 'Components'
import { FiCalendar } from 'react-icons/fi'

export function DatePicker({ field }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className={cn('justify-start text-left font-normal', !field.value && 'text-muted-foreground')}>
          <FiCalendar />
          {field.value ? field.value : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start"></PopoverContent>
    </Popover>
  )
}
