import { AdminProfile, ProfileHeader } from 'Components'
import { useSelector } from 'react-redux'

export const AdminAccountSettings = () => {
  const user = useSelector((state) => state.auth.session?.user)

  return (
    <>
      <ProfileHeader user={user} />

      <AdminProfile />
    </>
  )
}
