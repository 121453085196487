import { callAPI, registerCompanyQuery, selectUserAccountQuery } from 'API'

import { Actions } from 'Store'

export const loadAppScripts = () => {}

// will setup the initial store
export const loadAppInitialData = async () => {}

// saves the user profile
export const registerCompany = async (companyData, captchaToken) => {
  console.log('registerCompany', companyData, captchaToken)
  Actions.setIsLoadingAuth(true)
  if (!companyData) return null
  const { error, registerCompany: result } =
    (await callAPI({
      query: registerCompanyQuery(),
      variables: { companyData },
      allowPublic: true,
      captchaToken
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}

export const selectUserAccount = async (accountId) => {
  Actions.setIsLoadingAuth(true)
  if (!accountId) return null
  const { error, selectUserAccount: result } =
    (await callAPI({
      query: selectUserAccountQuery(),
      variables: { accountId }
    })) || {}
  const authTokens = error ? { error } : result?.session?.jwtId ? result : null
  Actions.onNewTokensArrived(authTokens)
}
