import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, T } from 'Components'
import { useSelector } from 'react-redux'
import { FiMail, FiUser } from 'react-icons/fi'

export const ProfileSettings = () => {
  const user = useSelector((state) => state.auth.session?.user)
  const fullName = [user.userName, user.userSurname].join(' ')

  const items = [
    { label: 'settings.personalDetails.title', value: fullName, icon: FiUser, to: '/settings/account/profile/details' },
    { label: 'settings.email.title', value: user.email, icon: FiMail, to: '/settings/account/profile/email' }
  ]

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">
        <T k="settings.profile.title" />
      </h3>

      <Section>
        {items.map(({ icon, to, label, value }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} hasBackground />
            <SectionItemContent title={T(label)} description={value} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
