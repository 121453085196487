export const ADMIN_ROUTES = {
  home: '/',
  contact: '/contact',
  whoami: '/whoami',
  logout: '/logout',
  login: '/login',
  'admin-users': '/admin-users',
  status: '/status',
  sessions: '/sessions',
  users: '/users',
  apps: '/apps',
  settings: '/settings',
  account: '/account',
  'settings.adminlogs': '/settings/adminlogs',
  'account.sessions': '/account/sessions',
  'account.logs': '/account/logs',
  'modals.app': '/modals/app'
}
