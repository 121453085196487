import { useQuery } from '@tanstack/react-query'
import { graphqlClient } from 'API'

export const useGraphQL = (queryFn, variables, options = {}) => {
  return useQuery({
    queryKey: [queryFn.name || 'graphql', variables],
    queryFn: async () => {
      const query = typeof queryFn === 'function' ? queryFn() : queryFn
      const result = await graphqlClient({ query, variables })
      return result
    },
    ...options
  })
}
