import { T } from 'Components'

export const AppSpv = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">
        <T k="settings.spv.title" />
      </h1>
    </div>
  )
}
